import React from 'react'
import './styles.scss'
import { renderRichText } from '../../utils'
import {
  BgPrimaryLeft,
  BgPrimaryCenter,
  BgPrimaryRight,
  BgPrimaryHoverLeft,
  BgPrimaryHoverRight,
} from '../svg/primary'
import {
  BgDefaultCenter,
  BgDefaultLeft,
  BgDefaultRight,
  BgDefaultHover,
} from '../svg/default'

export default function CtaButtonBlock({
  content,
  link = {},
  align = 'left',
  type = 'primary',
  height = 'default',
  onClick,
  bgColor = '#003fce',
  bgColorCenter = '',
  disabled = false,
  isSmall = false,
  isEditor = false,
  withSpacer = false,
  className = '',
  ...rest
}) {
  const icon = getIcon(link)
  return (
    <div
      className={`cta-button-wrapper ${'align-' + align} ${
        withSpacer ? 'with-spacer' : ''
      } ${'color-' + getTextColor(bgColor, type, bgColorCenter)} ${className}`}
      {...rest}
    >
      {React.createElement(
        link.url ? 'a' : 'button',
        {
          className:
            'dhsv-cta-button-block' +
            (isSmall ? ' is-small' : '') +
            ' ' +
            height,
          disabled,
          ...(!link.url ? { onClick } : {}),
          ...(type === 'default' ? { style: { color: bgColor } } : {}),
          ...(link.url
            ? {
                href: isEditor ? null : link.url,
                target: link.target,
                rel: link.rel,
              }
            : {}),
        },
        <>
          {type === 'primary' ? (
            <PrimarySvg {...{ bgColor, bgColorCenter }} />
          ) : (
            <DefaultSvg {...{ bgColor, bgColorCenter }} />
          )}

          <div className={'button-content'}>
            {renderRichText(content, 'span', {
              className: 'text-medium',
            })}
            <span className={`las ${icon}`} />
          </div>
        </>
      )}
    </div>
  )
}

const PrimarySvg = ({ bgColor, bgColorCenter }) => (
  <>
    <div className={'button-bg'}>
      <BgPrimaryLeft color={bgColor} colorInside={bgColorCenter} />
      <BgPrimaryCenter color={bgColor} colorInside={bgColorCenter} />
      <BgPrimaryRight color={bgColor} colorInside={bgColorCenter} />
    </div>
    <div className={'button-bg hover'}>
      <BgPrimaryHoverLeft color={bgColor} />
      <BgPrimaryHoverRight color={bgColor} />
    </div>
  </>
)

const DefaultSvg = ({ bgColor, bgColorCenter }) => (
  <>
    <div className={'button-bg'}>
      <BgDefaultLeft color={bgColor} colorInside={bgColorCenter} />
      <BgDefaultCenter color={bgColor} colorInside={bgColorCenter} />
      <BgDefaultRight color={bgColor} colorInside={bgColorCenter} />
    </div>
    <div className={'button-bg hover'}>
      <BgDefaultHover color={bgColor} />
    </div>
  </>
)

const getIcon = link => {
  if (link.url) {
    if (link.url.includes('tel:')) {
      return 'la-phone'
    } else if (link.target == '_blank') {
      return 'la-external-link-alt'
    } else {
      return 'la-arrow-right'
    }
  } else {
    return 'la-arrow-right'
  }
}

const getTextColor = (bgColor, type = 'primary', bgColorCenter = '') => {
  if (bgColorCenter !== '') {
    return 'blue'
  }

  if (type === 'default') {
    return 'inherit'
  }

  switch (bgColor) {
    case '#003fce':
    case '#2a2a48':
    case '#ff2e83':
    case '#2dcd6e':
    case '#ff7822':
      return 'white'
    case '#9bfd9a':
    case '#f5f5f5':
    case '#ffffff':
      return 'black'
    default:
      return 'black'
  }
}

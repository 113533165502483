/* global System */
import withLazyUnveil from 'scripts/higher-order-components/withLazyUnveil'

export default withLazyUnveil({
  loadComponent: () =>
    System.import(
      /* webpackChunkName: "LogosliderBlock" */
      './component'
    ),
  height: 220,
  mobileHeight: 147,
})

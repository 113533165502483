export const BgPrimaryLeft = ({ color = '#003ece', colorInside = '' }) => {
  if (colorInside !== '') {
    return ''
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.03"
      height="50"
      viewBox="0 0 24 50"
      className={'bg-left'}
      preserveAspectRatio={'none'}
    >
      <path
        d="M3.83,1.23c-1.23,0-2.64,0-3.45.06a1.28,1.28,0,0,0,0,.17,1.88,1.88,0,0,1-.26.87c0,.11,0,.23,0,.36l.08,0,0,.3a.63.63,0,0,1,.07.3.64.64,0,0,1,0,.15,5.47,5.47,0,0,1,0,1c0,.35,0,.76-.08,1.3a25.45,25.45,0,0,0,0,4.85v.11c-.07,3-.12,5.93,0,8.89a29.63,29.63,0,0,1,.16,8.08,40.25,40.25,0,0,0-.16,5.38,21.64,21.64,0,0,1,0,2.79,16.87,16.87,0,0,0,0,2.66C.21,40.44.31,45,.12,47a14.2,14.2,0,0,1,0,1.6c1.47,0,2.85,0,4.14,0,2.76,0,5.14.06,7.19,0h.21c4.1,0,8.23,0,12.34,0V1.55Q13.91,1.44,3.83,1.23Z"
        fill={color}
      />
    </svg>
  )
}

export const BgPrimaryCenter = ({ color = '#003ece', colorInside = '' }) => {
  if (colorInside == '') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="191"
        height="50"
        viewBox="0 0 191 50"
        className={'bg-center'}
        preserveAspectRatio={'none'}
      >
        <path
          d="M185.09,1.47,182,1.5l-4.78-.07c-8.34-.13-17-.26-25.46-.07-8.86-.11-17.84,0-26.52,0-6.94.06-14.11.11-21.17.08-16.16-.13-32.61,0-48.51.08C37,1.68,18,1.8-.88,1.55v47.1c13.37.05,26.6.2,38.13.44,4.4,0,8.8-.07,13.2-.12,9.38-.12,19.09-.23,28.65,0l.21,0c.9.1,7.55,0,12,0l5.1,0H97c8,.11,16.12.12,24,.14,10.24,0,20.83,0,31.27.27l.2,0a4.32,4.32,0,0,0,1.58,0,8.69,8.69,0,0,1,1.27-.07,9.15,9.15,0,0,1,1.71,0,3,3,0,0,0,.57,0l.15-.06h.14c9,.18,18.15,0,27-.1l7.06-.1V1.41C189.6,1.42,187.33,1.44,185.09,1.47Z"
          fill={color}
        />
      </svg>
    )
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio={'none'}
        width="237.4"
        height="52.004"
        viewBox="0 0 237.4 52.004"
        className={'bg-center'}
      >
        <g transform="translate(2.47 1.019)">
          <path
            d="M1.091-.188C-1.823-.2-4.6-.468-7.529-.5-14.327-.8-21.316-.644-28.17-.571-38.245-.714-48.3-.942-58.379-.714c-15.881-.2-31.738.2-47.61.121-40.528-.328-81.117.831-121.564-.255,0,0,0,0,0,0-1.587-.027-3.5.1-3.5.1-.3,1.067,0,.751-.359,1.569l-.066.018c.088-.006.045.879.066,1.059-.357.03.355.058,0,.091-.112.018.109.037,0,.018.346.012-.341,4.965,0,7.441-.057,3-.1,6.012,0,9.016.772,4.5-.216,8.985,0,13.491.14,1.839-.144,3.664,0,5.5.082,1.949.161,6.455,0,8.46a20.3,20.3,0,0,1,0,2.359c4.231,0,7.555.149,10.25,0,15.9,0,33.121.149,48.465.468,13.984-.064,27.867-.456,41.792-.133.488.216,15.1-.028,17.84,0,18.356.249,36.75.024,55.16.416.989.3,2.016-.049,3.087,0,1.29-.128,2.086.179,2.528,0,13.913.273,27.835-.21,41.754-.28,2.234.207,10.108,0,12.342-.045.211,0,.236-.925,0-2.1.28-.216.131-.462,0-.736.463-.7-.3-.97,0-1.681,1.625-4.841-.2-9.645,0-14.531-.819-4.236.629-8.478,0-12.713-.621-3.354-.053-6.677,0-10.046a8.275,8.275,0,0,1,0-1.554c.166-.361.729-.6,0-.708-.215-.629.543-1.152,0-1.581.916-.62-.932-1.231,0-1.839.884-1.01-.708-1.4-.708-1.4M-223.682,15.7c.137.049.275.1.413.143-.006.021-.012.042-.018.067-.132.021-.25.054-.374.082-.011-.1-.012-.195-.021-.292m.569-4.281a4.481,4.481,0,0,0-.678.265c0-.174,0-.346,0-.52.226.085.444.174.674.255M-3.6,12.382a.8.8,0,0,0,.1-.033c0,.113-.009.223-.013.335-.025-.015-.057-.037-.08-.051,0-.04,0-.083,0-.122s0-.085,0-.128m-6.657,33.28a12.354,12.354,0,0,0,2.73-.538c.065.265,2.964-.711-.193.2-.845-.03-1.691.362-2.537.334M-215.578,37.58c0-.033,0-.07,0-.107.05.021.1.04.15.058-.052.018-.1.033-.152.049m7.808-36.333c-.324,0-.647,0-.971.006-.05-.012-.1-.025-.155-.033l1.125.027m193.315.134c-2.912.015-5.786-.058-8.7-.04l-5.858-.149c4.87-.042,9.714.055,14.556.189M-231.485.839"
            transform="translate(231.522 0.849)"
            fill={color}
          />
          <path
            d="M1.091-.188C-1.823-.2-4.6-.468-7.529-.5-14.327-.8-21.316-.644-28.17-.571-38.245-.714-48.3-.942-58.379-.714c-15.881-.2-31.738.2-47.61.121-40.528-.328-81.117.831-121.564-.255,0,0,0,0,0,0-1.587-.027-3.5.1-3.5.1-.3,1.067,0,.751-.359,1.569l-.066.018c.088-.006.045.879.066,1.059-.357.03.355.058,0,.091-.112.018.109.037,0,.018.346.012-.341,4.965,0,7.441-.057,3-.1,6.012,0,9.016.772,4.5-.216,8.985,0,13.491.14,1.839-.144,3.664,0,5.5.082,1.949.161,6.455,0,8.46a20.3,20.3,0,0,1,0,2.359c4.231,0,7.555.149,10.25,0,15.9,0,33.121.149,48.465.468,13.984-.064,27.867-.456,41.792-.133.488.216,15.1-.028,17.84,0,18.356.249,36.75.024,55.16.416.989.3,2.016-.049,3.087,0,1.29-.128,2.086.179,2.528,0,13.913.273,27.835-.21,41.754-.28,2.234.207,10.108,0,12.342-.045.211,0,.236-.925,0-2.1.28-.216.131-.462,0-.736.463-.7-.3-.97,0-1.681,1.625-4.841-.2-9.645,0-14.531-.819-4.236.629-8.478,0-12.713-.621-3.354-.053-6.677,0-10.046a8.275,8.275,0,0,1,0-1.554c.166-.361.729-.6,0-.708-.215-.629.543-1.152,0-1.581.916-.62-.932-1.231,0-1.839.884-1.01-.708-1.4-.708-1.4M-231.485.839"
            transform="translate(231.522 0.849)"
            fill={colorInside}
            stroke={color}
            strokeWidth="2"
          />
        </g>
      </svg>
    )
  }
}

export const BgPrimaryRight = ({ color = '#003ece', colorInside = '' }) => {
  if (colorInside !== '') {
    return ''
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.03"
      height="50"
      viewBox="0 0 24.03 50"
      className={'bg-right'}
      preserveAspectRatio={'none'}
    >
      <path
        d="M19.71,49.13l3.65-.06h.5c0-.28-.05-2.5-.05-2.5s-.24-13.84-.16-15.67c-.52-2.27.37-10.67,0-12.6-.55-2.39,0-9.12,0-10.12a13.48,13.48,0,0,1,.08-1.84l0-.06a4.83,4.83,0,0,1,.06-1.37l0-.06,0-1a3.33,3.33,0,0,1,.08-2.31c-.09,0-.21,0-.33,0-1.84,0-3.66.08-5.42,0s-3.36,0-5.08,0c-4.35-.15-8.79-.18-13.2-.16V49.17c3.17,0,6.35-.06,9.5-.08C11.18,49.22,15.93,49.18,19.71,49.13Z"
        fill={color}
      />
    </svg>
  )
}

export const BgPrimaryHoverLeft = ({ color = '#003fce' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="3.63"
      height="50"
      viewBox="0 0 3.63 50"
      className={'bg-hover-left'}
      preserveAspectRatio={'none'}
    >
      <path
        d="M3.29,45.79l0-.77a16.45,16.45,0,0,0,0-2.22v-.1c.16-2,.08-6.36,0-8.17a23.1,23.1,0,0,1,0-2.76,20.35,20.35,0,0,0,0-2.59,49.08,49.08,0,0,1,.14-5.45,32.53,32.53,0,0,0-.13-7.65V16c-.1-2.93-.06-5.83,0-8.79a29.69,29.69,0,0,1,0-5c0-.39,0-.83.06-1.21-1.45,0-3,.1-3,.1-.3,1,0,.73-.36,1.53L0,2.65c.08,0,0,.86.06,1-.35,0,.36.06,0,.09-.06,0,0,0,0,0h0s0,0,0,0c.33.06-.34,4.85,0,7.26,0,2.93-.1,5.87,0,8.8C.87,24.25-.11,28.63.1,33c.14,1.8-.14,3.58,0,5.37s.16,6.31,0,8.26A17.81,17.81,0,0,1,.1,49c1.34,0,2,0,3.19,0V45.79Z"
        fill={color}
      />
    </svg>
  )
}

export const BgPrimaryHoverRight = ({ color = '#003fce' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="236.88"
      height="50"
      viewBox="0 0 236.88 50"
      className={'bg-hover-right'}
      preserveAspectRatio={'none'}
    >
      <path
        d="M236.78,47.19c0-.39,0-.5,0-.73a1.66,1.66,0,0,1,0-.33c-3.14.06-14.95.1-16.44,0l-2.46,0a5.29,5.29,0,0,1-1.11.15h0c.35,0,.69-.09,1-.15-4,0-8.1.1-12.07.16-8.76.14-17.82.28-26.74.11a3.24,3.24,0,0,1-1.08,0,7,7,0,0,0-1.47,0h-.11a8.47,8.47,0,0,0-1.1.06c-.33,0-.67.06-1,.06a4.05,4.05,0,0,1-1-.12c-10.32-.21-20.8-.24-30.93-.26-7.81,0-15.88,0-23.82-.15l-5.59,0c-9.33.08-11.53.06-12.2,0-9.45-.21-19.06-.1-28.36,0-4.37.05-8.74.11-13.13.13-14.44-.3-31.54-.46-48.19-.46-1.7.09-3.7.06-6,0-1.28,0-3.23,0-4.74,0H0v3.22c2.49,0,5.18.07,7,0,15.81,0,32.94.15,48.19.46,13.91-.06,27.71-.45,41.56-.13.48.21,15,0,17.73,0,18.26.24,36.55,0,54.85.41,1,.29,2,0,3.07,0,1.28-.13,2.07.17,2.51,0,13.84.27,27.68-.21,41.52-.28,2.22.21,18.13,0,20.35,0C237,49.25,236.8,47.57,236.78,47.19Z"
        fill={color}
      />
    </svg>
  )
}

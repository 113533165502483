import React, { useState, useEffect, useRef } from 'react'
import Popup from 'reactjs-popup'
import PropTypes from 'prop-types'
import './style.scss'
import MegaMenu from './MegaMenu'
import CtaButtonBlock from '../../../gutenberg/blocks/cta-button/component/component'
import DhsvButton from '../../../scripts/components/Button'
import MobileMenu from '../MobileMenu'
import LangSwitcher from '../LangSwitcher'
import { LangsList } from '../LangSwitcher/component'
import MegaMenuContent from './MegaMenuContent'

const {
  headerMenu = [],
  magazinMenu = [],
  // headerTopMenu = [],
  blogName,
  logo,
  logoSecond,
  navOverlay,
  magazinLogoTop,
  magazinLogoBottom,
  languages,
  header_buttom,
  headerTopMenuWP = [],
  isMagazin,
  socialLinks,
  langSwitcher,
  magazinDarkTheme,
  magazinMenuMobile,
  menuMobile,
  currentActivePage,
  magazinLogoType,
} = window.theme_global_data

const Header = () => {
  useSticky()

  return (
    <header className={'dhsv_header'}>
      {isMagazin ? <MagazinHeader /> : <DefaultHeader />}
    </header>
  )
}

const MagazinHeader = () => {
  return (
    <div
      className={`default-header ${
        magazinDarkTheme ? 'default-header--dark' : ''
      } `}
    >
      <MainHeaderMagazin />
    </div>
  )
}

const DefaultHeader = () => {
  return (
    <div className={'default-header'}>
      <TopHeaderWP />
      {/*<TopHeader />*/}
      <MainHeader navOverlay={navOverlay} />
    </div>
  )
}

// const TopHeader = () => {
//   return (
//     <div className={'top-header'}>
//       <div className="container">
//         {headerTopMenu.length > 0 && (
//           <ul>
//             {headerTopMenu.map((item, key) => (
//               <TopMenuItem key={key} {...item} />
//             ))}
//             <LangSwitcher />
//           </ul>
//         )}
//       </div>
//     </div>
//   )
// }

const TopHeaderWP = () => {
  return (
    <div className={'top-header'}>
      <div className="container">
        {headerTopMenuWP.length > 0 && (
          <ul>
            {headerTopMenuWP.map((item, key) => (
              <TopMenuItemWP key={key} {...item} />
            ))}
            <LangSwitcher />
          </ul>
        )}
      </div>
    </div>
  )
}

const MainHeaderMagazin = () => {
  const sticky = useSticky()
  const [langOpened, setLangOpened] = useState(false)
  //const [show, setShow] = useState(false)
  const [openSearch, setSearchOpen] = useState(false)
  const closeModal = () => setSearchOpen(false)
  const [openSubmenu, setOpenSubmenu] = useState('')

  useEffect(() => {
    const body = document.querySelector('body')
    if (openSubmenu) {
      body.classList.add('menu-opened')
    } else {
      body.classList.remove('menu-opened')
    }
  }, [openSubmenu])

  return (
    <>
      <div className={'main-header'}>
        <div className="container">
          <div className={'wrapper'}>
            <div className="magazin_logo brand">
              {magazinLogoType == 'default' ? (
                <Brand />
              ) : (
                <div>
                  <MagazinBrand />
                  <MagazinBrandBottom />
                </div>
              )}
            </div>
            <DhsvButton url="#" style="primary-black" isPreview />
            {magazinMenu.length > 0 && (
              <nav className="magazin-main-nav">
                <ul
                  className={`navbar`}
                  style={{ top: !sticky ? '175px' : '94px' }}
                >
                  {magazinMenu.map((item, key) => (
                    <MenuItem
                      key={key}
                      {...{ ...item, openSubmenu, setOpenSubmenu }}
                    />
                  ))}
                </ul>
                {socialLinks.length > 0 && (
                  <ul className="social_links">
                    {socialLinks.map((link, key) => (
                      <li key={key}>
                        <a
                          href={link.social_link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className={`lab la-${link.social_media}`}></i>
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
                <div className="magazin-nav-right">
                  {/*<a href="javascript:void(0)">*/}
                  {/*  <i className="las la-bookmark"></i>*/}
                  {/*</a>*/}
                  <a
                    href="javascript:void(0)"
                    onClick={() => setSearchOpen(o => !o)}
                  >
                    <i className="las la-search"></i>
                  </a>
                  <Popup open={openSearch} onClose={closeModal}>
                    <div className="modal">
                      <form
                        method="get"
                        className="header_search search-form"
                        action={`${document.location.origin}${
                          window.theme_global_data.currentLang !== 'de'
                            ? '/' + window.theme_global_data.currentLang + '/'
                            : ''
                        }`}
                      >
                        <input
                          type="search"
                          name="s"
                          className="search-field"
                          placeholder={
                            window.theme_global_data.strings.searchTerm
                          }
                          autoComplete="off"
                        />
                        <input type="hidden" name="post_type" value="post" />
                        <CtaButtonBlock
                          content={window.theme_global_data.strings.search}
                        />
                      </form>
                    </div>
                  </Popup>
                  <div className="magazin-lang-switcher">
                    <a
                      href="javascript:void(0)"
                      onClick={() => setLangOpened(value => !value)}
                    >
                      <span
                        dangerouslySetInnerHTML={{ __html: langSwitcher }}
                      ></span>
                    </a>
                    <LangsList languages={languages} open={langOpened} />
                  </div>
                </div>
                <MobileMenu {...magazinMenuMobile} />
              </nav>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const MainHeader = ({ navOverlay }) => {
  const ctaRef = useRef()
  const sticky = useSticky()
  const [navPos, setNavPos] = useState(0)
  const [show] = useState(false)
  const [openSubmenu, setOpenSubmenu] = useState('')

  useEffect(() => {
    const body = document.querySelector('body')
    if (openSubmenu) {
      body.classList.add('menu-opened')
    } else {
      body.classList.remove('menu-opened')
    }
  }, [openSubmenu])

  useEffect(() => {
    if (ctaRef.current && !sticky) {
      setNavPos(ctaRef.current.getBoundingClientRect().width + 20)
    } else {
      setNavPos(0)
    }
    const body = document.querySelector('body')
    if (navOverlay) {
      body.classList.add('overlay')
    } else {
      body.classList.remove('overlay')
    }
  }, [sticky, ctaRef])

  return (
    <>
      <div className={'main-header'}>
        <div className="container">
          <div className={'wrapper'}>
            <Brand show={show} />
            {headerMenu.length > 0 && (
              <nav>
                <ul
                  className={`navbar`}
                  style={{ top: !sticky ? '175px' : '94px' }}
                >
                  {headerMenu.map((item, key) => (
                    <MenuItem
                      key={key}
                      {...{ ...item, openSubmenu, setOpenSubmenu }}
                    />
                  ))}
                </ul>
                <div
                  style={{ marginRight: -navPos }}
                  className={'cta-section'}
                  ref={ctaRef}
                >
                  {header_buttom.url && (
                    <CtaButtonBlock
                      link={{
                        url: header_buttom.url,
                        target: header_buttom.target,
                      }}
                      content={header_buttom.title}
                      type={'primary'}
                      isSmall={true}
                    />
                  )}
                </div>
                <MobileMenu {...menuMobile} />
              </nav>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const Brand = ({ show }) => (
  <div>
    {logo && (
      <a href={logo.link} className={`brand ${show ? 'open-menu' : ''}`}>
        <img
          src={logo.src}
          width={logo.width}
          height={logo.height}
          alt={blogName}
        />
      </a>
    )}
    {logoSecond && (
      <a
        href={logoSecond.link}
        className={`brand second ${show ? 'open-menu' : ''}`}
      >
        <img
          src={logoSecond.src}
          width={logoSecond.width}
          height={logoSecond.height}
          alt={blogName}
        />
      </a>
    )}
  </div>
)

const MagazinBrand = () => (
  <a href={magazinLogoTop.link} className={'brand__item'}>
    <img
      src={magazinLogoTop.src}
      width={magazinLogoTop.width}
      height={magazinLogoTop.height}
      alt={blogName}
    />
  </a>
)

const MagazinBrandBottom = () => (
  <a href={magazinLogoBottom.link} className={'brand__item'}>
    <img
      src={magazinLogoBottom.src}
      width={magazinLogoBottom.width}
      height={magazinLogoBottom.height}
      alt={blogName}
    />
  </a>
)

const MenuItem = ({
  permalink,
  title,
  items = [],
  active,
  object_id,
  openSubmenu,
  setOpenSubmenu,
  post_id,
  item_posttype,
  megaMenuData,
}) => {
  const isMegaMenuItem = item_posttype === 'mega-menu'
  const megaMenu = isMegaMenuItem ? { post_id, megaMenuData } : {}
  const hasItems =
    items.filter(i => i.fields.template_element === '').length > 0
  const megaMenuItem = items.find(i => i.fields.template_element !== '')
  const megaMenuId = megaMenuItem ? megaMenuItem.fields.template_element : false
  const open = openSubmenu === object_id

  if (permalink === '#chat') {
    permalink = "javascript:Intercom('show')"
  }

  return (
    <li
      className={
        (active || currentActivePage === object_id ? 'active' : '') +
        (open ? ' open-menu' : '')
      }
      //onMouseEnter={() => setOpenSubmenu(object_id)}
    >
      {megaMenuId || isMegaMenuItem ? (
        <>
          <button onClick={() => setOpenSubmenu(open ? 0 : object_id)}>
            {title}
          </button>
          {megaMenu && isMegaMenuItem ? (
            <MegaMenuContent
              item={megaMenu}
              open={open}
              onClose={() => setOpenSubmenu(0)}
              //onMouseEnter={() => setOpenSubmenu(object_id)}
              //onMouseLeave={() => setOpenSubmenu(0)}
            />
          ) : (
            <MegaMenu
              id={megaMenuId}
              open={open}
              onClose={() => setOpenSubmenu(0)}
              //onMouseEnter={() => setOpenSubmenu(object_id)}
              //onMouseLeave={() => setOpenSubmenu(0)}
            />
          )}
        </>
      ) : (
        <>
          <a href={permalink}>{title}</a>
          {hasItems && (
            <ul className={'submenu'}>
              {items.map((item, key) => (
                <MenuItem key={key} {...item} />
              ))}
            </ul>
          )}
        </>
      )}
    </li>
  )
}

MenuItem.propTypes = {
  permalink: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.array,
  active: PropTypes.bool,
  object_id: PropTypes.string,
  openSubmenu: PropTypes.string,
  setOpenSubmenu: PropTypes.func,
  post_id: PropTypes.string,
  item_posttype: PropTypes.string,
  megaMenuData: PropTypes.object,
}

const TopMenuItem = ({ menu_item, icon }) => {
  return (
    <li>
      <a href={menu_item.url} target={menu_item.target}>
        <Icon slug={icon} />
        <span className={'title'}>{menu_item.title}</span>
      </a>
    </li>
  )
}

const TopMenuItemWP = menu_item => {
  return (
    <li>
      {menu_item && (
        <a href={menu_item.permalink} target={menu_item.target}>
          <Icon slug={menu_item.fields.icons} />
          <span className={'title'}>{menu_item.title}</span>
        </a>
      )}
    </li>
  )
}

const Icon = ({ slug }) => {
  switch (slug) {
    case 'arrow':
      return <i className="las la-arrow-right"></i>
    case 'arrows':
      return <i className="las la-exchange-alt"></i>
    case 'clock':
      return <i className="las la-check-circle"></i>
    case 'user':
      return <i className="las la-user"></i>
    case 'phone':
      return <i className="las la-phone"></i>
    case 'handshake':
      return <i className="las la-handshake"></i>
    default:
      return null
  }
}

const BurgerIcon = ({ show = false, setShow = () => {} }) => (
  <button
    className={'burger-nav' + (show ? ' open' : '')}
    onClick={() => {
      setShow(!show)
    }}
  >
    <span />
    <span />
    <span />
  </button>
)

Brand.propTypes = {
  show: PropTypes.bool,
}

BurgerIcon.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
}

MainHeader.propTypes = {
  navOverlay: PropTypes.bool,
}

Icon.propTypes = {
  slug: PropTypes.string,
}

TopMenuItem.propTypes = {
  icon: PropTypes.string,
  menu_item: PropTypes.object,
}

TopMenuItemWP.propTypes = {
  icon: PropTypes.string,
  menu_item: PropTypes.object,
}

Header.propTypes = {
  isMobile: PropTypes.bool,
  isMagazin: PropTypes.bool,
}

const useSticky = () => {
  const [isSticky, setSticky] = useState(false)

  useEffect(() => {
    const handleSticky = () => {
      const sticky = window.scrollY > 60
      setSticky(sticky)
      if (sticky) {
        document.body.classList.add('sticky')
      } else {
        document.body.classList.remove('sticky')
      }
    }

    handleSticky()
    window.addEventListener('scroll', handleSticky)
  }, [])

  return isSticky
}

export default Header

import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import ImageTag from '../ImageTag'

const StageImage = item => {
  return (
    <div className={'image-item'}>
      <StageImageTag
        imgData={item.layer}
        style={{ objectPosition: item.alignment, objectFit: item.bgSize }}
      />
    </div>
  )
}

StageImage.propTypes = {
  id: PropTypes.number,
  speed: PropTypes.number,
  style: PropTypes.object,
}

export const StageImageTag = ({ imgData = {}, speed = 0, style }) => {
  const imgRef = useRef()
  const [imgStyles, satImgStyles] = useState({})

  useEffect(() => {
    const handleScroll = () => {
      if (imgRef.current) {
        const rect = imgRef.current.getBoundingClientRect()
        const scrollTop = rect.top - 75
        const styleTop = (scrollTop * speed) / 100
        satImgStyles({
          transform: `translateY(${styleTop}px)`,
        })
      }
    }

    handleScroll()

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [imgRef])

  return imgData ? (
    <div ref={imgRef}>
      <ImageTag {...imgData} style={{ ...imgStyles, ...style }} lazy={false} />
    </div>
  ) : (
    false
  )
}

StageImageTag.propTypes = {
  imgData: PropTypes.object,
  speed: PropTypes.number,
  style: PropTypes.object,
}

export default StageImage

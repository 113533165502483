/* global System */
import withLazyUnveil from 'scripts/higher-order-components/withLazyUnveil'
// register component(s) with react habitat

export default {
  KooperationenBlock: withLazyUnveil({
    loadComponent: () =>
      System.import(
        /* webpackChunkName: "KooperationenBlock" */
        './component'
      ),
    height: 1240,
    mobileHeight: 2770,
  }),
}

import React from 'react'
import PropTypes from 'prop-types'

const Picture = ({ withPictureTag = true, lazy = true, ...image }) => {
  if (!('source_url' in image)) {
    return <div className={'image_placeholder'} />
  }

  const sources = image.media_details
    ? Object.values(image.media_details.sizes)
    : []

  return withPictureTag ? (
    <picture
      style={image.style}
      className={image.className || image.image_class || ''}
    >
      {sources.map((source, key) => (
        <source
          key={key}
          type={source.mime_type}
          media={`(max-width:${source.width}px)`}
          {...{ [lazy ? 'data-srcset' : 'srcset']: source.source_url }}
        />
      ))}
      <img
        alt={image.alt_text}
        style={image.style}
        {...(lazy
          ? {
              className: 'lazyload',
              'data-src': image.source_url,
            }
          : { src: image.source_url })}
      />
    </picture>
  ) : (
    <img
      className={`${image.className || image.image_class || ''}${
        lazy ? ' lazyload' : ''
      }`}
      alt={image.alt_text}
      style={image.style}
      {...{ [lazy ? 'data-src' : 'src']: image.source_url }}
    />
  )
}

Picture.propTypes = {
  media_details: PropTypes.object,
  source_url: PropTypes.string,
  alt_text: PropTypes.string,
  image_class: PropTypes.string,
  withPictureTag: PropTypes.bool,
  lazy: PropTypes.bool,
}

export default Picture

/* global System */
import withLazyUnveil from 'scripts/higher-order-components/withLazyUnveil'
// register component(s) with react habitat

export default {
  ArrowDown: withLazyUnveil({
    loadComponent: () =>
      System.import(
        /* webpackChunkName: "ArrowDown" */
        './ArrowDown'
      ),
    height: 695,
    mobileHeight: 467,
  }),
  BackgroundSVG: withLazyUnveil({
    loadComponent: () =>
      System.import(
        /* webpackChunkName: "BackgroundSvg" */
        './Background'
      ),
    height: 695,
    mobileHeight: 467,
  }),
}

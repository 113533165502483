import React from 'react'
import PropTypes from 'prop-types'

const DrilldownItem = props => {
  return (
    <li className="menu-item">
      {props.backButton ? (
        <React.Fragment>
          <span className={`back-button ${props.goToMenu}`}>
            <i className="btr bt-angle-left" />
          </span>
          <a href="#" onClick={() => props.setActiveMenu(props.goToMenu || 0)}>
            {props.children}
          </a>
        </React.Fragment>
      ) : (
        <a href={props.url}>{props.children}</a>
      )}
      {props.rightIcon ? (
        <span
          className={`icon-right ${props.goToMenu} ${
            props.url == '#' ? 'full-width-arrow' : ''
          }`}
          onClick={() => props.goToMenu && props.setActiveMenu(props.goToMenu)}
        >
          {props.rightIcon}
        </span>
      ) : (
        ''
      )}
    </li>
  )
}

DrilldownItem.propTypes = {
  setActiveMenu: PropTypes.func.isRequired,
  goToMenu: PropTypes.any,
  children: PropTypes.any,
  backButton: PropTypes.bool,
  rightIcon: PropTypes.any,
  url: PropTypes.string,
}

export default DrilldownItem

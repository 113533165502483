import React, { useState, useEffect, useRef } from 'react'
import apiFetch from '@wordpress/api-fetch'
import ReactDom from 'react-dom'
import PropTypes from 'prop-types'
import app from 'scripts/init/react/Bootstrapper'
import { getLanguageSlug } from 'scripts/util/l10n'

const MegaMenu = ({
  id,
  open,
  onClose = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {},
}) => {
  const wrapperRef = useRef()
  const [data, loading, init] = useMegaMenuContent(id)

  useEffect(() => {
    app.update(wrapperRef.current)
  }, [data, loading])

  return data.content
    ? ReactDom.createPortal(
        <div className={'dhsv_header-megamenu' + (open ? ' open' : '')}>
          <button
            title={'Menü schließen'}
            className={'overlay'}
            onClick={onClose}
          />
          <div
            className={'content'}
            ref={wrapperRef}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <button
              title={'Menü schließen'}
              className={'overlay overlay-inner'}
              onClick={onClose}
            />
            <div className="container">
              {loading && init ? (
                'loading...'
              ) : (
                <div
                  dangerouslySetInnerHTML={{ __html: data.content.rendered }}
                />
              )}
            </div>
          </div>
        </div>,
        document.body
      )
    : null
}

MegaMenu.propTypes = {
  id: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

const useMegaMenuContent = id => {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [init, setInit] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    setLoading(true)
    apiFetch({
      path: `${getLanguageSlug()}/wp-json/wp/v2/template-element/${id}`,
    })
      .then(fetchData => {
        setData(fetchData)
        setLoading(false)
        setInit(false)
        setError(false)
      })
      .catch(e => {
        setError(e)
        setLoading(false)
        setInit(false)
      })
  }, [id])

  return [data, loading, init, error]
}

export default MegaMenu

import React from 'react'
import { ReactSVG } from 'react-svg'
import PropTypes from 'prop-types'

export default function ReactSVGWrapper({ src, ...rest }) {
  return <ReactSVG src={src + '?not-from-cache'} {...rest} />
}

ReactSVGWrapper.propTypes = {
  src: PropTypes.string.isRequired,
  rest: PropTypes.object,
}

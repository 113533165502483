import './styles.scss'
import '../akkordeon-item/styles.scss'

const AKK_CLASS = '.dhsv-akkordeon'

document.addEventListener(
  'DOMContentLoaded',
  function () {
    const akkordeons = Array.from(document.querySelectorAll(AKK_CLASS))
    akkordeons.forEach(handleAkkordeon)
  },
  false
)

const handleAkkordeon = item => {
  const props = JSON.parse(item.getAttribute('data-props'))
  const children = Array.from(item.querySelectorAll(`${AKK_CLASS}--item`))
  const allOpenButton = item.querySelector(`button.show-all-button`)
  let state = children.map((x, i) => i === props.selected)
  console.log(allOpenButton, children, state, props) //eslint-disable-line

  const renderState = () => {
    children.forEach((child, key) => {
      if (state[key]) {
        child.classList.add('open')
        child.setAttribute('aria-expanded', true)
      } else {
        child.classList.remove('open')
        child.setAttribute('aria-expanded', false)
      }

      animateHeight(child, state[key])
      if (allOpenButton) {
        renderAllOpenButton()
      }
    })
  }

  const renderAllOpenButton = () => {
    const allClosed = !state.includes(true)
    const text = allOpenButton.getAttribute(
      allClosed ? 'data-open' : 'data-close'
    )
    allOpenButton.querySelector(':scope > span').innerHTML = text
    if (!allClosed) {
      allOpenButton.classList.add('open')
    } else {
      allOpenButton.classList.remove('open')
    }
  }

  const animateHeight = (child, open = true) => {
    const body = child.querySelector(`${AKK_CLASS}--item__body`)
    body.style.transition = 'height 0.25s ease'
    const height = body.querySelector(`:scope > div`).clientHeight

    if (open) {
      body.style.height = `${height}px`
      setTimeout(() => {
        body.style.height = 'auto'
      }, 250)
    } else if (parseFloat(body.style.height) !== 0) {
      body.style.transition = 'none'
      body.style.height = height + 'px'
      body.style.transition = 'height 0.25s ease'
      setTimeout(() => {
        body.style.height = 0
      }, 50)
    }
  }

  const handleChildClick = (child, key) => {
    console.log(key, state) //eslint-disable-line
    state = state.map((x, i) => {
      const isThisOpen = x && key === i
      return isThisOpen ? false : props.onlyOne ? i === key : !x ? i === key : x
    })
    renderState()
  }

  const handleAhowAll = () => {
    console.log(allOpenButton.classList) //eslint-disable-line
    state = state.map(() => !allOpenButton.classList.contains('open'))
    renderState()
  }

  renderState()
  children.forEach((child, key) => {
    child.addEventListener('click', () => handleChildClick(child, key))
  })

  if (allOpenButton) {
    allOpenButton.addEventListener('click', handleAhowAll)
  }
}

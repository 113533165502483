import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import Magazin from '../Magazin'
import PlatformItem from '../PlatformItem'

export default function MegaMenuContent({
  item,
  open,
  onClose,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
}) {
  const wrapperRef = useRef()

  return ReactDOM.createPortal(
    <div className={'dhsv_header-megamenu' + (open ? ' open' : '')}>
      <button
        title={'Menü schließen'}
        className={'overlay'}
        onClick={onClose}
      />
      <div
        className={'content'}
        ref={wrapperRef}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <button
          title={'Menü schließen'}
          className={'overlay overlay-inner'}
          onClick={onClose}
        />
        <div className="container">
          <div className="close-main-menu" onClick={onClose}>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </div>
          <MegaMenuInner {...item} />
        </div>
      </div>
    </div>,
    document.body
  )
}

const MegaMenuInner = item => {
  const menuData = item.megaMenuData

  const base = 100 / 12
  return (
    <div className={'mega-menu-inner'} data-menu-id={item.post_id}>
      <div className={'wp-block-columns'}>
        <div
          className={'wp-block-column'}
          style={{ flexBasis: `${base * 8}%` }}
        >
          <PlatformItem
            platformElement={menuData.repeater.map(item => {
              return {
                url: { url: item.link },
                title: item.headline,
                icon: { url: item.icon },
                descr: item.text_area,
                decor: { url: item.decor },
              }
            })}
          />
        </div>
        {menuData && (
          <div className={'wp-block-column'}>
            <Magazin
              title={menuData.magazin_headline}
              category={
                !menuData.show_single_magazine_entry &&
                menuData.magazin_kategorie
                  ? menuData.magazin_kategorie
                  : false
              }
              singleId={
                menuData.show_single_magazine_entry && menuData.magazin_beitrag
                  ? menuData.magazin_beitrag
                  : false
              }
              count={1}
            />
          </div>
        )}
      </div>
    </div>
  )
}

MegaMenuContent.propTypes = {
  item: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

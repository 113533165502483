/* global System */
// register component(s) with react habitat

export default {
  ControlTestBlock: () =>
    System.import(
      /* webpackChunkName: "ControlTestBlock" */
      './component'
    ),
}

/* global System */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import './style.scss'

const loadIconFunc = slug =>
  System.import(
    /* webpackChunkName: "icon-[request]" */
    '!svg-inline-loader!images/icons/' + slug + '.svg'
  )

const SvgIcon = ({ slug = '', className = '', loadIcon = loadIconFunc }) => {
  const [icon, updateIcon] = useState('')

  useEffect(() => {
    if (!slug) {
      updateIcon('')
      return
    }
    loadIcon(slug)
      .then(({ default: icon }) => updateIcon(icon))
      .catch(e => {
        updateIcon('')
        console.log(e)
      })
  }, [slug])

  return (
    <div
      className={`dhsv-svg-icon${className ? ` ${className}` : ''}${
        slug ? ` ${slug}` : ''
      }`}
      dangerouslySetInnerHTML={{
        __html: icon,
      }}
    />
  )
}

SvgIcon.propTypes = {
  slug: PropTypes.string,
  className: PropTypes.string,
  loadIcon: PropTypes.func,
  lazy: PropTypes.bool,
}

export default SvgIcon

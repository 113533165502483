import { devMode } from '../../util/env'
import { getLanguageSlug } from 'scripts/util/l10n'
import { buildQueryString } from '../../util/string'

export const LOAD_GFORM = 'rb001/gform/LOAD_GFORM'
export const LOAD_GFORM_SUCCESS = 'rb001/gform/LOAD_GFORM_SUCCESS'
export const LOAD_GFORM_FAILURE = 'rb001/gform/LOAD_GFORM_FAILURE'

export const initialState = {
  gformsLoading: [],
  gformsLoaded: {},
  error: null,
}

export default (state = initialState, action) => {
  const { type, payload = {} } = action

  switch (type) {
    case LOAD_GFORM:
      return {
        ...state,
        gformsLoading: [...state.gformsLoading, payload],
      }
    case LOAD_GFORM_SUCCESS:
      return {
        ...state,
        gformsLoading: state.gformsLoading.filter(item => item !== payload.id),
        gformsLoaded: {
          ...state.gformsLoaded,
          ...{ [payload.id]: payload.data },
        },
      }
    case LOAD_GFORM_FAILURE:
      return {
        ...state,
        gformsLoading: state.gformsLoading.filter(item => item !== payload.id),
        error: payload.error,
      }
    default:
      return state
  }
}

export const loadGform = id => dispatch => {
  const query = buildQueryString({
    id,
  })

  dispatch({
    type: LOAD_GFORM,
    payload: id,
  })

  fetch(`${getLanguageSlug()}/wp-json/dhsv/v1/gform?${query}`)
    .then(response => {
      if (![200, 400].includes(response.status)) {
        const message = response.statusText
          ? response.statusText
          : response.status

        throw new Error(message)
      }

      response.json().then(json => {
        dispatch({
          type: LOAD_GFORM_SUCCESS,
          payload: json,
        })
      })
    })
    .catch(error => {
      if (devMode) console.error(error) //eslint-disable-line
      dispatch({
        payload: {
          error: error,
          id,
        },
        type: LOAD_GFORM_FAILURE,
      })
      throw error
    })
}

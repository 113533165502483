import React from 'react'
import PropTypes from 'prop-types'

import './StageImages.scss'

import StageImage from './StageImage'

const StageImages = ({ layers = [], isBack = true, bgSize = 'contain' }) => {
  return (
    <div className={'dhsv_stage__image ' + (isBack ? 'back' : 'front')}>
      {layers.map((item, key) => (
        <StageImage key={key} {...item} bgSize={bgSize} />
      ))}
    </div>
  )
}

StageImages.propTypes = {
  layers: PropTypes.array,
  isBack: PropTypes.bool,
  bgSize: PropTypes.string,
}

export default StageImages

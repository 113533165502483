/* global System */
import React, { lazy, useEffect, useState, Suspense } from 'react'
import PropTypes from 'prop-types'

import './style.scss'

import { ArrowCenter } from './Svgs'
import StageImages from './StageImages'
import StageContent from './StageContent'
import {
  getButtonType,
  renderRichText,
  translateColor,
} from '../../../gutenberg/blocks/utils'
import LogosliderBlock from '../../../gutenberg/blocks/logoslider/component/lazy'
import CtaButtonBlock from '../../../gutenberg/blocks/cta-button/component/component'

const StageVideo = lazy(() =>
  System.import(
    /* webpackChunkName: "StageVideo" */
    './StageVideo'
  )
)

const Stage = props => {
  const [isMobile, setMobile] = useState(false)
  const {
    backImageLayer = [],
    frontImageLayer = [],
    bgSize = 'contain',
    zIndex = 2,
    useLogoSlider,
    logosliderTitle = '',
    buttonLink,
    buttonTitle,
    buttonColor,
    subButtonLink,
    subButtonTitle,
    subButtonColor,
    ctaImage,
    isEditor = false,
  } = props

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth < 992)
    }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const logosliderAlign = isMobile
    ? 'below-stage'
    : props.logosliderAlign || 'with-content'

  const Button = () =>
    buttonLink.url ? (
      <CtaButtonBlock
        align={isMobile ? 'wide' : 'left'}
        content={buttonTitle}
        type={getButtonType(buttonColor)}
        link={
          isEditor
            ? {}
            : {
                url: buttonLink.url,
                target: buttonLink.opensInNewTab ? '_blank' : '',
              }
        }
        bgColor={translateColor(buttonColor)}
      />
    ) : null

  const SubButton = () =>
    subButtonLink.url ? (
      <CtaButtonBlock
        align={isMobile ? 'wide' : 'left'}
        content={subButtonTitle}
        type={getButtonType(subButtonColor)}
        link={
          isEditor
            ? {}
            : {
                url: subButtonLink.url,
                target: subButtonLink.opensInNewTab ? '_blank' : '',
              }
        }
        bgColor={translateColor(subButtonColor)}
      />
    ) : null

  // const CtaImage = ctaImage =>
  //   ctaImage && ctaImage.url ? (
  //     isEditor ? (
  //       {}
  //     ) : (
  //       <a
  //         href={ctaImage.url}
  //         target={ctaImage.opensInNewTab ? '_blank' : ''}
  //         className={'dhsv_stage__link'}
  //         rel="noreferrer"
  //       ></a>
  //     )
  //   ) : null

  return (
    <div
      className={
        'dhsv_stage bg-' +
        props.bgColor +
        (props.useSmallStage ? ' small-stage-height' : '') +
        (props.useLogoSlider ? ' use-logoslider ' + logosliderAlign : '')
      }
      style={{ zIndex: zIndex }}
    >
      {renderRichText(props.title, 'h1', {
        className: 'dhsv_stage-title',
      })}
      {!isMobile && (
        <StageImages layers={backImageLayer} isBack={true} bgSize={'cover'} />
      )}
      <StageContent
        {...props}
        logosliderAlign={logosliderAlign}
        button={
          !isMobile ? (
            <>
              <Button />
              <SubButton />
            </>
          ) : null
        }
      />
      {isMobile ? (
        <StageImages
          layers={[...backImageLayer, ...frontImageLayer]}
          isBack={true}
          bgSize={'cover'}
        />
      ) : (
        <>
          <StageImages
            layers={frontImageLayer}
            isBack={false}
            bgSize={bgSize}
          />
          {ctaImage &&
            ctaImage.url &&
            (!isEditor ? (
              <a
                href={ctaImage.url}
                target={ctaImage.opensInNewTab ? '_blank' : ''}
                className={'dhsv_stage__link'}
                rel="noreferrer"
              ></a>
            ) : null)}
        </>
      )}
      {(buttonLink.url || subButtonLink.url) && isMobile && (
        <div className={'button-mobile'}>
          <Button />
          <SubButton />
        </div>
      )}
      {props.useArrowCenter && <ArrowCenter top={props.arrowCenterPosition} />}
      {props.showVideo && (
        <Suspense fallback={() => null}>
          <StageVideo showVideo={props.showVideo} videoLink={props.videoLink} />
        </Suspense>
      )}
      {useLogoSlider &&
        logosliderAlign === 'below-stage' &&
        'apiUrl' in props.data && (
          <div className={'logo-slider-wrapper'}>
            <div className={'container'}>
              <div className="row">
                <LogosliderBlock {...props} headline={logosliderTitle} />
              </div>
            </div>
          </div>
        )}
    </div>
  )
}

Stage.defaultProps = {
  backImageLayer: [],
  frontImageLayer: [],
  useArrowCenter: true,
  useSmallStage: false,
  isEditor: false,
  useLogoSlider: false,
  arrowCenterPosition: 50,
  title: 'Headline',
  content: '',
  bgColor: 'white',
  bgSize: 'contain',
  zIndex: 2,
}

Stage.propTypes = {
  backImageLayer: PropTypes.array,
  frontImageLayer: PropTypes.array,
  isEditor: PropTypes.bool,
  useArrowCenter: PropTypes.bool,
  useSmallStage: PropTypes.bool,
  useLogoSlider: PropTypes.bool,
  arrowCenterPosition: PropTypes.number,
  title: PropTypes.any,
  content: PropTypes.any,
  showVideo: PropTypes.bool,
  videoLink: PropTypes.string,
  bgColor: PropTypes.string,
  bgSize: PropTypes.string,
  logosliderTitle: PropTypes.string,
  logosliderAlign: PropTypes.string,
  data: PropTypes.object,
  zIndex: PropTypes.number,
  buttonLink: PropTypes.object,
  buttonTitle: PropTypes.string,
  buttonColor: PropTypes.string,
  subButtonTitle: PropTypes.string,
  subButtonLink: PropTypes.object,
  subButtonColor: PropTypes.string,
  ctaImage: PropTypes.object,
}

export default Stage

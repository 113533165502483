import React, { useState } from 'react'
import { getFullLangName } from '../../routes/common'
import PropTypes from 'prop-types'

const { languages, currentLang } = window.theme_global_data

export default function LangSwitcher() {
  const [open, setOpen] = useState(false)
  return (
    <li
      className={'lang-switcher'}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <button className={'trigger'}>
        <span>{getFullLangName(currentLang)}</span>
        <i className="las la-angle-down"></i>
      </button>
      <LangsList {...{ languages, open }} />
    </li>
  )
}

export const LangsList = ({ languages, open }) => {
  return (
    languages.length > 0 && (
      <ul className={'langs' + (open ? ' open' : '')}>
        <LangItem lang={'de'} />
        {languages
          .filter(l => l.public)
          .map((lang, key) => (
            <LangItem key={key} lang={lang.language_to} />
          ))}
      </ul>
    )
  )
}

LangsList.propTypes = {
  languages: PropTypes.array,
  open: PropTypes.bool,
}

export const LangItem = ({ lang = '' }) => {
  const isLangPage = location.pathname.includes(`/${currentLang}/`)
  const addPath = isLangPage
    ? location.pathname.replace(`/${currentLang}`, '')
    : location.pathname
  let langLink = `${location.origin}${
    lang !== 'de' ? '/' + lang : ''
  }${addPath}`

  const isSearchPage = document.body.classList.contains('search')
  if (isSearchPage) {
    const queryString = window.location.search
    langLink = `${location.origin}${
      lang !== 'de' ? '/' + lang + '/' : ''
    }${queryString}`
  }

  return (
    <li className={currentLang === lang ? 'active' : ''}>
      <a href={langLink}>{getFullLangName(lang)}</a>
    </li>
  )
}

LangItem.propTypes = {
  lang: PropTypes.object,
}

/* global System */
import withLazyUnveil from 'scripts/higher-order-components/withLazyUnveil'
// register component(s) with react habitat

export default {
  HeadlineBlock: withLazyUnveil({
    loadComponent: () =>
      System.import(
        /* webpackChunkName: "HeadlineBlock" */
        './component'
      ),
    height: 85,
    mobileHeight: 110,
  }),
}

import React, { createElement } from 'react'
import PropTypes from 'prop-types'
import LoadingIndicator from '../LoadingIndicator'
import './style.scss'

const Button = ({
  alignment,
  title,
  url,
  target,
  rel,
  style,
  color_scheme,
  inlined,
  classes,
  dataSet,
  disabled,
  callback,
  loading,
  children,
  isPreview,
  fullWidth,
}) => {
  if (!children && !title) {
    return false
  }

  let type = 'button'
  let props = {
    className: `btn btn-${style} btn-color--${color_scheme}`,
  }

  if (disabled) {
    props.className += ' btn--disabled'
  }

  if (classes.length) {
    classes.map(className => {
      props.className += ' ' + className
    })
  }
  if (url) {
    props.href = url
    type = 'a'
  }
  if (target) props.target = target
  if (rel) props.rel = rel

  if (callback && typeof callback === 'function') {
    props.onClick = () => callback(dataSet)
  }

  if (isPreview && !callback) {
    props = { ...props, onClick: e => e.preventDefault() }
  }

  if (fullWidth) {
    props.className += ' btn--fullwidth'
  }

  let icon = ''

  if (url) {
    if (url.includes('tel:')) {
      icon = 'la-phone'
    } else if (target == '_blank') {
      icon = 'la-external-link-alt'
    } else {
      icon = 'la-arrow-right'
    }
  }

  if (typeof dataSet !== 'undefined' && Object.keys(dataSet).length > 0) {
    let newDataSet = new Object()
    for (let dataProp in dataSet) {
      Object.assign(newDataSet, { [`data-${dataProp}`]: dataSet[dataProp] })
    }

    props = { ...props, ...newDataSet }
  }

  return (
    <div
      className={`dhsv_button align-${alignment}${
        inlined ? ' is-inlined' : ''
      }`}
    >
      {createElement(
        type,
        props,
        <>
          {children ? children : decodeEntities(title)}
          {style !== 'link' && (
            <span className={`btn-overlay`}>
              <span>{!loading && <i className={`las ${icon}`} />}</span>
            </span>
          )}

          {loading && <LoadingIndicator />}
          {!loading && <i className={`las ${icon}`} />}
        </>
      )}
    </div>
  )
}

function decodeEntities(encodedString) {
  var textArea = document.createElement('textarea')
  textArea.innerHTML = encodedString
  return textArea.value
}

Button.defaultProps = {
  style: 'primary',
  color_scheme: 'secondary',
  alignment: 'left',
  classes: [],
  inlined: false,
  loading: false,
  isPreview: false,
}

Button.propTypes = {
  alignment: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  style: PropTypes.string,
  color_scheme: PropTypes.string,
  inlined: PropTypes.bool,
  classes: PropTypes.array,
  dataSet: PropTypes.object,
  disabled: PropTypes.bool,
  callback: PropTypes.func,
  loading: PropTypes.bool,
  children: PropTypes.any,
  isPreview: PropTypes.bool,
  onClick: PropTypes.func,
  fullWidth: PropTypes.bool,
}

export default Button

import React from 'react'
import PropTypes from 'prop-types'
// import { ReactSVG } from 'react-svg'
import ReactSVG from '../ReactSVGWrapper'
import Picture from '../Picture'

const ImageTag = ({ withPictureTag = true, lazy = true, ...imgData }) => {
  const imgProps =
    'source_url' in imgData
      ? {
          src: imgData.source_url,
          alt: imgData.alt_text,
        }
      : {
          src: imgData.url,
          alt: imgData.alt,
        }

  return imgProps.src ? (
    <Picture
      withPictureTag={withPictureTag}
      className={imgData.className}
      style={imgData.style}
      ref={imgData.ref}
      source_url={imgProps.src}
      alt_text={imgProps.alt}
      lazy={lazy}
    />
  ) : null
}

ImageTag.propTypes = {
  source_url: PropTypes.string,
  url: PropTypes.string,
  alt_text: PropTypes.string,
  alt: PropTypes.string,
  style: PropTypes.object,
  ref: PropTypes.any,
  withPictureTag: PropTypes.bool,
  className: PropTypes.string,
  lazy: PropTypes.bool,
}

export const ImageTagSvg = imgData => {
  const imgProps =
    'source_url' in imgData
      ? {
          src: imgData.source_url,
        }
      : {
          src: imgData.url,
        }

  // console.log('ImageTagSvg', imgProps, imgData) //eslint-disable-line
  return imgProps.src ? (
    <ReactSVG
      src={imgProps.src}
      beforeInjection={svg => {
        svg.classList.add(imgData.className)
      }}
    />
  ) : (
    false
  )
}

ImageTagSvg.propTypes = {
  source_url: PropTypes.string,
  url: PropTypes.string,
  alt_text: PropTypes.string,
  alt: PropTypes.string,
  style: PropTypes.object,
  ref: PropTypes.any,
  className: PropTypes.string,
}

export default ImageTag

/* global System */
import withLazyUnveil from 'scripts/higher-order-components/withLazyUnveil'
// register component(s) with react habitat

export default {
  PostsGrid: withLazyUnveil({
    loadComponent: () =>
      System.import(
        /* webpackChunkName: "PostsGrid" */
        './component'
      ),
    height: 2450,
    mobileHeight: 8100,
  }),
}

import React from 'react'
import PropTypes from 'prop-types'
import { renderRichText } from '../../../gutenberg/blocks/utils'
import LogosliderBlock from '../../../gutenberg/blocks/logoslider/component/lazy'

const StageContent = ({
  title,
  content,
  buttonLink,
  subButtonLink,
  button,
  logosliderTitle,
  useLogoSlider,
  logosliderAlign = 'with-content',
  ...props
}) => {
  return (
    <div className={'dhsv_stage__content'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'half-width'}>
            <div className={'text-wrapper'}>
              {renderRichText(title, 'h1')}
              {renderRichText(content, 'p')}
              {(buttonLink.url || subButtonLink.url) && (
                <div className={'buttons'}>{button}</div>
              )}
            </div>
            {useLogoSlider &&
              logosliderAlign === 'with-content' &&
              'apiUrl' in props.data && (
                <LogosliderBlock {...props} headline={logosliderTitle} />
              )}
          </div>
        </div>
      </div>
    </div>
  )
}

StageContent.propTypes = {
  buttonLink: PropTypes.object,
  subButtonLink: PropTypes.object,
  buttonTitle: PropTypes.any,
  buttonColor: PropTypes.string,
  logosliderTitle: PropTypes.any,
  button: PropTypes.element,
  title: PropTypes.any,
  content: PropTypes.any,
  useLogoSlider: PropTypes.bool,
  logosliderAlign: PropTypes.oneOf(['with-content', 'below-stage']),
  data: PropTypes.object,
}

export default StageContent

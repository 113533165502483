import React, { useState, useEffect } from 'react'
import { parse, stringify } from 'query-string'

export const renderRichText = (
  value,
  tagName = 'div',
  props = {},
  changeContent = x => x
) => {
  return typeof value === 'string'
    ? React.createElement(tagName, {
        ...props,
        dangerouslySetInnerHTML: { __html: changeContent(value) },
      })
    : value
}

export const richTextinSlider = e => {
  e.stopPropagation()
  e.currentTarget.focus()
}

export const useRestAPI = (url = '', init = [], exclude = [], fields = []) => {
  const [data, setData] = useState(init)
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [totalItems, setTotalItems] = useState(0)

  const urlComponents = url.split('?')

  const queryObj = {
    ...parse(urlComponents[1]),
    ...(exclude.length ? { exclude: exclude.join(',') } : {}),
    ...(fields.length ? { _fields: fields.join(',') } : {}),
  }

  const queryString = stringify(queryObj)

  useEffect(() => {
    if (url) {
      setLoading(true)
      fetch(`${urlComponents[0]}${queryString ? '?' + queryString : ''}`)
        .then(resp => {
          setTotalPages(Number(resp.headers.get('x-wp-totalpages')))
          setTotalItems(Number(resp.headers.get('x-wp-total')))
          return resp.json()
        })
        .then(restData => {
          setData(restData)
          setLoading(false)
        })
    }
  }, [url, exclude.length])

  return [data, loading, totalPages, totalItems]
}

export const getFixedPosts = (apiUrl, fixedIds = [], ptQuery, fields = []) => {
  const ids = fixedIds.length > 0 ? fixedIds : [0]
  const fixedUrl = apiUrl
    ? apiUrl.split('?')[0] +
      `?include=${ids.join(',')}&order=${
        ptQuery.order
      }&orderby=include&per_page=100`
    : ''
  const [fixed] = useRestAPI(fixedUrl, [], [], fields)

  return fixed.length > 0 ? fixed : []
}

export const bgColors = [
  {
    name: 'Blue',
    slug: 'rb_blue',
    color: '#003fce',
  },
  {
    name: 'Blue middle',
    slug: 'rb_blue-middle',
    color: '#d8e2f7',
  },
  {
    name: 'Blue light',
    slug: 'rb_blue-light',
    color: '#f2f5fc',
  },
  {
    name: 'Fuchsia',
    slug: 'rb_fuchsia',
    color: '#ff2e83',
  },
  {
    name: 'Fuchsia middle',
    slug: 'rb_fuchsia-middle',
    color: '#ffdfec',
  },
  {
    name: 'Fuchsia light',
    slug: 'rb_fuchsia-light',
    color: '#fff2f7',
  },
  {
    name: 'Green',
    slug: 'rb_green',
    color: '#9bfd9a',
  },
  {
    name: 'Green middle',
    slug: 'rb_green-middle',
    color: '#d6fed6',
  },
  {
    name: 'Green light',
    slug: 'rb_green-light',
    color: '#ebfeea',
  },
  {
    name: 'Dark',
    slug: 'rb_dark',
    color: '#2a2a48',
  },
  {
    name: 'Dark middle',
    slug: 'rb_dark-middle',
    color: '#dfdfe3',
  },
  {
    name: 'Dark light',
    slug: 'rb_dark-light',
    color: '#f0f0f2',
  },
  {
    name: 'White',
    slug: 'rb_white',
    color: '#ffffff',
  },
  {
    name: 'Transparent',
    slug: 'rb_transparent',
    color: 'transparent',
  },
]

export const getColorCode = color => {
  return (
    {
      light: '#f2f5fc',
      blue: '#003fce',
      dark: '#2a2a48',
      pink: '#ff2e83',
    }[color] || color
  )
}

export const getTextColor = color => {
  switch (getColorCode(color)) {
    case '#ff2e83':
    case '#003fce':
    case '#2a2a48':
      return '#ffffff'
    default:
      return '#000000'
  }
}

export const getTextColorClass = color => {
  switch (getColorCode(color)) {
    case '#ff2e83':
    case '#003fce':
    case '#2a2a48':
      return 'light'
    default:
      return 'dark'
  }
}

export const getButtonType = colorString => {
  return colorString.includes('primary')
    ? 'primary'
    : colorString.includes('secondary')
    ? 'default'
    : 'default'
}

export const translateColor = colorString => {
  return (
    {
      primary: '#003fce',
      'primary-green': '#9bfd9a',
      'primary-fuchsia': '#ff2e83',
      'primary-black': '#2a2a48',
      secondary: '#003fce',
      'secondary-green': '#9bfd9a',
      'secondary-black': '#2a2a48',
      'secondary-fuchsia': '#ff2e83',
      link: '',
    }[colorString] || '#003fce'
  )
}

export const isCalendlyLink = link => {
  const hasCalendlyLink =
    typeof link === 'string' ? link.includes('calendly.com') : false
  let calendlyLink = '#'
  let label = ''

  if (hasCalendlyLink) {
    let div = document.createElement('div')
    div.innerHTML = link
    calendlyLink = div.querySelector('a').href
    label = div.querySelector('a').innerText
  }

  const handleCalendlyClick = event => {
    event.preventDefault()
    const calendlyUrl = getCalendlyLinkTranslation(calendlyLink)
    window.Calendly.initPopupWidget({ url: calendlyUrl })
  }

  return { hasCalendlyLink, calendlyLink, label, handleCalendlyClick }
}

export const getCalendlyLinkTranslation = link => {
  const lang = window.theme_global_data.currentLang

  let href = link
  href.replace(/\s+/g, '')

  if (lang !== 'de') {
    const calendlyLinks = window.theme_global_data.calendlyLinks

    let linkObj = calendlyLinks.flatMap(obj =>
      obj.links.find(link => link.lang === 'de' && link.url === href) ? obj : []
    )
    linkObj = linkObj.length > 0 ? linkObj[0] : undefined

    if (linkObj) {
      const langLink = linkObj.links.find(link => link.lang === lang)
      if (langLink) {
        href = langLink.url
      } else {
        const englishLink = linkObj.links.find(link => link.lang === 'en')
        if (englishLink) {
          href = englishLink.url
        }
      }
    }
  }

  return href
}

export function arrayMove(arr, oldIndex, newIndex) {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
  return arr // for testing
}

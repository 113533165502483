/* global System */
import withLazyUnveil from 'scripts/higher-order-components/withLazyUnveil'
// register component(s) with react habitat

export default {
  Advantages: withLazyUnveil({
    loadComponent: () =>
      System.import(
        /* webpackChunkName: "Advantages" */
        './index'
      ),
    height: 835,
    mobileHeight: 800,
  }),
}

export const BgDefaultLeft = ({ color = '#003ece' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.03"
      height="50"
      viewBox="0 0 24.03 50"
      className={'bg-left'}
      preserveAspectRatio={'none'}
    >
      <path
        d="M12.5,46.9c-0.9,0.1-2,0.1-3.4,0.1H5.4H2.3v-0.6c0.1-2.8,0.1-5.7,0-8.5c-0.1-0.8-0.1-1.7,0-2.5
		c0.1-1,0.1-1.9,0-2.9c-0.1-1.7,0-3.5,0.2-5.2c0.3-2.7,0.3-5.5-0.2-8.2c-0.1-2.9-0.1-5.7,0-8.6v-0.3c-0.1-1.5-0.1-3.1,0-4.6
		c0.1-0.5,0.1-1,0.1-1.3c0-0.3,0-0.7,0-1c0,0,0-0.1,0-0.1c0-0.2,0-0.3-0.1-0.5V2.6V2.1c0.6,0,1.4,0,2.1,0h0.5
		c5.7,0.1,12.3,0.2,19.9,0.3v-2C18.2,0.4,10.6,0.2,3.9,0.1H3.4c-1.1,0-2.2,0-3,0.1c0,0.1,0,0.1,0,0.2c0,0.3-0.1,0.6-0.3,0.9
		c0,0.1,0,0.2,0,0.4h0.1v0.3c0,0.1,0.1,0.2,0.1,0.3c0,0,0,0.1,0,0.2c0,0.4,0,0.7,0,1.1c0,0.4,0,0.8-0.1,1.3c-0.2,1.7-0.2,3.3,0,5
		v0.1C0.1,13,0.1,16,0.2,19.1c0.4,2.8,0.5,5.6,0.2,8.3c-0.2,1.9-0.2,3.7-0.2,5.6c0.1,1,0.1,1.9,0,2.9c-0.1,0.9-0.1,1.8,0,2.8
		c0.1,2,0.2,6.6,0,8.8c0,0.5,0,1.1,0,1.7c1.5,0,2.8,0,4.1,0h3.7c1.2,0,2.4,0,3.5-0.1h0.2h12h1.1v-2.1H12.7H12.5z"
        fill={color}
      />
    </svg>
  )
}

export const BgDefaultCenter = ({ color = '#003ece' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="191"
      height="50"
      viewBox="0 0 191 50"
      className={'bg-center'}
      preserveAspectRatio={'none'}
    >
      <path
        d="M54.9,2.5c10.7-0.1,21.8-0.1,32.9-0.1c5.9,0,11.2,0,16.3,0.1h4.4c5.8,0,11.8,0,17.1-0.1c5.5,0,11.2-0.1,17-0.1
		c3.6,0,6.8,0,9.9,0c2.9-0.1,5.9-0.1,9.3-0.1c5.5,0,11,0.1,16.4,0.2h0.1l4.9,0.1h3.2c1.7,0,3.5,0,5.2-0.1v-2c-2.2,0-4.3,0-6.5,0.1
		h-3.1l-4.8,0c-5.3-0.1-10.8-0.2-16.2-0.2c-3.1,0-6.2,0-9.2,0.1c-3.2,0-6.5-0.1-9.8-0.1c-5.6,0-11.2,0-16.7,0.1
		c-5.5,0-11.2,0.1-16.8,0.1H104c-5.3,0-10.7-0.1-16.1-0.1C77,0.3,66.2,0.4,55.5,0.4s-21.2,0.1-32,0.1c-8,0-16,0-24-0.1v2
		c7,0.1,14.6,0.1,22.9,0.1C33.5,2.6,44.9,2.5,54.9,2.5z"
        fill={color}
      />
      <path
        d="M185.9,47.7c-5.9,0.1-12.1,0.2-18.2,0.2c-3.3,0-6.3,0-9.1-0.1h-0.1h-0.2l-0.2,0.1h-0.3c-0.4,0-0.8-0.1-1.1-0.1
		c-0.2,0-0.5,0-0.7,0h-0.2c-0.4,0-0.8,0-1.2,0.1c-0.3,0-0.6,0.1-0.8,0.1c-0.1,0-0.3,0-0.4,0h-0.1h-0.1h-0.2h-0.1
		c-10.6-0.2-21.3-0.2-31.7-0.3c-7.9,0-16.2,0-24.3-0.1h-0.6h-5h-0.3c-2.7,0-6,0-8.5,0s-3.2,0-3.3,0H79h-0.1
		c-3.7-0.1-7.7-0.1-12.1-0.1c-5.7,0-11.4,0.1-17,0.1c-4.4,0-8.9,0.1-13.3,0.1c-11.2-0.2-23.9-0.4-36.9-0.4V49h-0.3
		c13.3,0,26.6,0.2,38.1,0.5c4.4,0,8.8-0.1,13.2-0.1c5.5-0.1,11.1-0.1,16.7-0.1c4,0,8,0,11.9,0.1h0.2c0.4,0,1.7,0.1,3.4,0.1
		c2.5,0,5.9,0,8.5,0h5.1h0.6c8,0.1,16.1,0.1,24,0.2c10.2,0,20.8,0,31.2,0.3h0.2c0.2,0.1,0.5,0.1,0.7,0.1c0.3,0,0.6,0,0.9-0.1
		c0.3,0,0.7-0.1,1.1-0.1h0.2c0.2,0,0.4,0,0.7,0c0.4,0,0.8,0,1,0c0.2,0,0.3,0,0.5,0h0.1l0.1-0.1h0.1c3,0.1,6,0.1,9,0.1
		c6,0,12.1-0.1,18-0.2l6.7-0.1v-1.9L185.9,47.7z"
        fill={color}
      />
    </svg>
  )
}

export const BgDefaultRight = ({ color = '#003ece' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.03"
      height="50"
      viewBox="0 0 24.03 50"
      className={'bg-right'}
      preserveAspectRatio={'none'}
    >
      <path
        d="M23.6,30.7c-0.5-2.4,0.4-11,0-13c-0.5-2.5,0-9.4,0-10.4c0-0.6,0-1.3,0.1-1.9v0c0-0.5,0-1,0.1-1.4c0,0,0,0,0-0.1v-1
		c-0.2-0.8-0.2-1.6,0.1-2.4h-0.1h-0.2h-0.4c-1.2,0-2.3,0-3.5,0c-0.6,0-1.1,0-1.7,0s-1,0-1.5,0c-1,0-2.1,0-3.1,0h-0.4
		C9.4,0.3,5.6,0.3,1.9,0.3h-2.8v2h2c4.3,0,7.8,0.1,11.1,0.2h0.5h1.6h1.5c0.4,0,0.9,0,1.4,0s1.1,0,1.7,0h2.8c0,0.5,0,1,0.2,1.5l0,0.5
		c-0.1,0.5-0.1,0.9-0.1,1.4V6c-0.1,0.7-0.1,1.3-0.1,2c0,0.2,0,0.6,0,1c-0.1,2.3-0.4,7.2,0.1,9.2c0.1,1.7,0.1,3.5-0.1,5.2
		c-0.2,2.4-0.2,4.8,0,7.2c0,1.4,0,7.2,0.1,11.4c0,1.6,0,3.1,0,3.7V46c0,0,0,0.8,0,1.5l-3.1,0c-2.4,0-4.3,0-5.9,0
		c-2,0-3.5-0.1-4.2-0.1H8.5l-9.4,0.1v2h1.1c3,0,6.2-0.1,9.2-0.1c0.9,0.1,2.5,0.1,4.4,0.1c1.9,0,4.1,0,6,0l3.6-0.1h0.5
		c0-0.3,0-2.6,0-2.6S23.5,32.6,23.6,30.7z"
        fill={color}
      />
    </svg>
  )
}

export const BgDefaultHover = ({ color = '#003fce' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="241.86"
      height="50"
      viewBox="0 0 241.86 50"
      className={'bg-hover-right'}
      preserveAspectRatio={'none'}
    >
      <path
        d="M235.5,43.6c-1.9,0-3.5,0-4.4-0.1c-3,0-6.3,0.1-9.3,0.1l-7.1,0.1c-5.9,0.1-12,0.2-18.1,0.2c-3,0-6.1,0-9.1-0.1h-0.1
		l-0.1,0.1h-0.1h-0.5c-0.3,0-0.7-0.1-1-0.1H185h-0.2c-0.4,0-0.7,0-1.1,0.1c-0.3,0.1-0.6,0.1-0.9,0.1c-0.2,0-0.5,0-0.7-0.1H182
		c-10.5-0.3-21.2-0.3-31.5-0.3c-7.9,0-16.1,0-24.1-0.2h-0.6h-5.1c-2.6,0.1-6,0.1-8.6,0.1c-1.8,0-3.1-0.1-3.5-0.1h-0.2
		c-4-0.1-8-0.1-12-0.1c-5.7,0-11.3,0.1-16.8,0.1c-4.4,0.1-8.9,0.1-13.3,0.1c-11.6-0.2-24.9-0.4-38.4-0.5H16.7h-0.2
		c-1.1,0.1-2.2,0.1-3.5,0.1h-2.8H6.1c0-0.6,0-1.1,0-1.6c0.2-2.1,0.1-6.8,0-8.8c-0.1-0.9,0-1.8,0-1.8s0-2.8,0-2.9
		c-0.1-1.9,0-3.7,0.2-5.5c0.3-2.8,0.3-5.6-0.2-8.4C6,11.1,6,8.1,6.2,5V4.8C6,3.3,6,1.7,6.1,0.2c-0.1,0-0.2,0-0.3,0H5.4
		c-1.1,0-4.3,0-5,0.1c0,0.1,0,0.1,0,0.2c0,0.3-0.1,0.6-0.3,0.9c0,0.1,0,0.2,0,0.4h0.1l0,0.3c0,0.1,0.1,0.2,0.1,0.3
		c0,0.1,0,0.1,0,0.2c0,0.3,0,0.7,0,1c0,0.4,0,0.8-0.1,1.3C0,6.5,0,8.1,0.2,9.8V10c-0.1,3.1-0.2,6.1,0,9.2c0.4,2.8,0.5,5.6,0.2,8.4
		c-0.2,1.8-0.2,3.7-0.2,5.5c0,0.1,0,2.9,0,2.9s-0.1,1.8,0,2.8c0.1,2,0.2,6.6,0,8.8c0,0.5,0,1.1,0,1.6h4.2H8c1.3,0,2.4,0,3.5-0.1h0.2
		h12.1c13.5,0.1,26.8,0.2,38.4,0.5c4.4,0,8.8,0,13.3-0.1c5.5-0.1,11.2-0.1,16.8-0.1c4,0,8,0,12,0.1h0.2c0.4,0,1.7,0.1,3.5,0.1
		c2.5,0,6,0,8.6-0.1h5.1h0.6c8,0.1,16.2,0.1,24.1,0.2c10.3,0,21,0,31.5,0.3h0.2c0.2,0.1,0.5,0.1,0.7,0.1c0.3,0,0.6,0,0.9-0.1
		c0.4,0,0.7-0.1,1.1-0.1h0.2h0.7c0.3,0,0.7,0,1,0.1h0.5h0.1l0.1-0.1h0.1c3,0.1,6.1,0.1,9.1,0.1c6.1,0,12.1-0.1,18.1-0.2l7.1-0.1
		c3,0,6.3-0.1,9.3-0.1c0.9,0.1,2.5,0.1,4.4,0.1s4.1,0,6-0.1l3.7-0.1h0.5c0-0.3,0-2.6,0-2.6s0-1.9,0-3.4l-0.1,0
		C239.6,43.6,237.4,43.6,235.5,43.6z"
        fill={color}
      />
    </svg>
  )
}

/* global System */
import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from 'scripts/components/SvgIcon'

// this components only supports a specific list of icons, because including many hundreds of icons in the bundle
// when only a few are used, slows down deployments significantly and also increases the size of the main js chunk
//
// whenn adding support for additional icons, don't forget to also add the slug to the webpackInclude regex
// because webpackInclude defines which files are actually included in the bundle
const supportedIcons = [
  'angle-right-solid',
  'arrow-right-solid',
  'search-solid',
  //social media
  'dribbble',
  'facebook',
  'instagram',
  'linkedin-in',
  'pinterest-p',
  'soundcloud',
  'tumblr',
  'twitter',
  'vimeo-v',
  'youtube',
  'xing',
]

const LineAwesomeIcon = ({ slug, ...other }) => {
  if (supportedIcons.includes(slug)) {
    return (
      <SvgIcon
        {...other}
        loadIcon={slug =>
          System.import(
            /* webpackChunkName: "line-awesome-[request]" */
            /* webpackInclude: /(angle-right-solid|arrow-right-solid|search-solid|dribbble|facebook|instagram|linkedin-in|pinterest-p|soundcloud|tumblr|twitter|vimeo-v|youtube|xing)\.svg/ */
            `!svg-inline-loader!line-awesome/svg/${slug}.svg`
          )
        }
        slug={slug}
      />
    )
  }
  console.error(`icon slug '${slug}' is not supported!`)
  return null
}

LineAwesomeIcon.propTypes = {
  slug: PropTypes.oneOf(supportedIcons),
}

export default LineAwesomeIcon

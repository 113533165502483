import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import apiFetch from '@wordpress/api-fetch'
import { getLanguageSlug } from 'scripts/util/l10n'
import './style.scss'

const Magazin = ({
  posts = [],
  title,
  category = false,
  singleId = false,
  count = 2,
}) => {
  const [data, setData] = useState([])

  useEffect(() => {
    loadData()
  }, [posts.length, title])

  const loadData = () => {
    const fieldsToFetch = ['featured_img_src', 'link', 'title']
    const fieldsToFetchStr = `&_fields=${fieldsToFetch.join(',')}`
    let path = category
      ? `${getLanguageSlug()}/wp-json/wp/v2/posts?categories=${category}&per_page=${count}&orderby=date&order=desc${fieldsToFetchStr}`
      : posts.length > 0
      ? `${getLanguageSlug()}/wp-json/wp/v2/posts?include=${posts}&per_page=${count}${fieldsToFetchStr}`
      : `${getLanguageSlug()}/wp-json/wp/v2/posts?per_page=${count}&orderby=date&order=desc${fieldsToFetchStr}`

    if (singleId) {
      path = `${getLanguageSlug()}/wp-json/wp/v2/posts?include[]=${singleId}${fieldsToFetchStr}`
    }

    apiFetch({
      path,
      posts,
    })
      .then(res => {
        setData(res)
      })
      .catch(error => {
        if (process.env.NODE_ENV === 'development') {
          console.error(error) //eslint-disable-line
        }
      })
  }

  return (
    <div className={'magazin-teaser'}>
      <span className={'h5 magazin-teaser__title'}>{title}</span>
      {data &&
        data.map((item, key) => (
          <div className="magazin-teaser-item" key={key}>
            <a href={item.link}>
              <img
                className="lazyload"
                data-src={item.featured_img_src}
                alt={item.featured_img_src}
              />
              <p
                className="magazin-teaser-item__title"
                dangerouslySetInnerHTML={{ __html: item.title.rendered }}
              ></p>
              <span className="magazin-teaser-item__link">
                {window.theme_global_data.strings.readMore}
              </span>
            </a>
          </div>
        ))}
    </div>
  )
}

Magazin.propTypes = {
  posts: PropTypes.array,
  category: PropTypes.string,
  title: PropTypes.string,
  error: PropTypes.bool,
  count: PropTypes.number,
  singleId: PropTypes.number,
}

export default Magazin

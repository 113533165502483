/* global System */
import withLazyUnveil from 'scripts/higher-order-components/withLazyUnveil'
// register component(s) with react habitat

export default {
  NewsletterBannerBlock: withLazyUnveil({
    loadComponent: () =>
      System.import(
        /* webpackChunkName: "NewsletterBannerBlock" */
        './component'
      ),
    height: 642,
    mobileHeight: 540,
  }),
}

/* global System */
import withLazyUnveil from 'scripts/higher-order-components/withLazyUnveil'
// register component(s) with react habitat

export default {
  CtaPerson: withLazyUnveil({
    loadComponent: () =>
      System.import(
        /* webpackChunkName: "CtaPerson" */
        './index'
      ),
    height: 575,
    mobileHeight: 890,
  }),
}

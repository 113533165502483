import React from 'react'
import PropTypes from 'prop-types'
import './style.scss'
import { ImageTagSvg } from '../ImageTag'

const PlatformItemIcon = ({ items = [] }) => {
  return (
    <div className={'dhsv_platform_items'}>
      <div className={'row'}>
        {items.map((item, key) => (
          <div className={'col-md-6 col-lg-4'} key={key}>
            <div className={'platform-item'}>
              <a
                href={item.url ? item.url.url : ''}
                className="platform-item__link"
              >
                <div className={'dhsv_platform_items'}>
                  <div className={'platform-item__decor'}>
                    <ImageTagSvg {...item.decor} />
                  </div>
                  <div className={'platform-item__icon'}>
                    <ImageTagSvg {...item.icon} />
                  </div>
                </div>
                {item.title && (
                  <p
                    className={'h3 platform-item__title'}
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                )}
              </a>
              {item.descr && (
                <p
                  className={'platform-item__descr'}
                  dangerouslySetInnerHTML={{ __html: item.descr }}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const PlatformItem = props => {
  return <PlatformItemIcon items={props.platformElement} />
}

PlatformItemIcon.propTypes = {
  items: PropTypes.array,
}

PlatformItem.defaultProps = {
  platformElement: [],
}

PlatformItem.propTypes = {
  platformElement: PropTypes.array,
}

export default PlatformItem

// import external dependencies
import 'jquery'

// import everything from autoload
import './autoload/_bootstrap.js'; import './autoload/_social-share-count.js' // eslint-disable-line

// import vc-elements javascript files
import '../vc_elements/_new/main.js'; import '../vc_elements/button/main.js'; import '../vc_elements/template_element/main.js' // eslint-disable-line

// import gutenberd javascript files
 // eslint-disable-line

//import scripts for akkordeon-block
import '../gutenberg/blocks/akkordeon/functions' // eslint-disable-line

// import react habitat and react components
import './init/react'

// import local dependencies
import Router from './util/Router'
import common from './routes/common'
import home from './routes/home'
import aboutUs from './routes/about'
import single from './routes/single'

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
  // Single page,
  single,
})

// Load Events
jQuery(document).ready(() => routes.loadEvents())

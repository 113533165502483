import React from 'react'
import './styles.scss'

const LoadingIndicator = () => {
  return (
    <div className="dhsv_loading_indicator">
      <div />
      <div />
      <div />
    </div>
  )
}

export default LoadingIndicator

/* global System */
// register component(s) with react habitat

export default {
  ImageTag: () =>
    System.import(
      /* webpackChunkName: "ImageTag" */
      './index'
    ),
}

import { createResponsiveStateReducer } from 'redux-responsive'

// bootstrap $grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1920px);
export default createResponsiveStateReducer({
  xs: 575,
  sm: 767,
  md: 991,
  lg: 1199,
  xl: 1919,
})

export const sBrowser = state => state.browser
export const sIsMobile = state => state.browser.lessThan.sm

/* global System */
// register component(s) with react habitat

export default {
  CustomerLogos: () =>
    System.import(
      /* webpackChunkName: "CustomerLogos" */
      './index'
    ),
}

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './style.scss'
import Menu from 'react-burger-menu/lib/menus/push'
import Drilldown from './drilldown'

const MobileMenu = ({
  browser,
  menuItems = [],
  topMenuItems = [],
  strings,
  arrowIcon,
  bottomHtml,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('mobile-menu-open')
    } else {
      document.body.classList.remove('mobile-menu-open')
    }
  }, [isMenuOpen])

  const { isMagazin } = window.theme_global_data

  let burgerMenuSettings = {
    width: '100%',
    right: true,
    disableAutoFocus: true,
    isOpen: isMenuOpen,
    customBurgerIcon: (
      <button className={'burger-nav' + (isMenuOpen ? ' open' : '')}>
        <span />
        <span />
        <span />
      </button>
    ),
    onClose: () => setIsMenuOpen(false),
    onStateChange: state => setIsMenuOpen(state.isOpen),
  }

  return menuItems.length && browser.lessThan.xl ? (
    <Menu {...burgerMenuSettings}>
      <Drilldown items={menuItems} strings={strings} arrowIcon={arrowIcon} />
      <div
        className="mobile_menu__bottom"
        dangerouslySetInnerHTML={{ __html: bottomHtml }}
      />
      {!isMagazin && topMenuItems.length && browser.lessThan.md ? (
        <Drilldown
          items={topMenuItems}
          strings={strings}
          arrowIcon={arrowIcon}
        />
      ) : (
        ''
      )}
    </Menu>
  ) : (
    ''
  )
}

MobileMenu.propTypes = {
  browser: PropTypes.object,
  menuItems: PropTypes.array,
  topMenuItems: PropTypes.array,
  strings: PropTypes.object,
  arrowIcon: PropTypes.string,
  bottomHtml: PropTypes.string,
}

export default MobileMenu

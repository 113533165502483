/* global System */
import withLazyUnveil from 'scripts/higher-order-components/withLazyUnveil'
// register component(s) with react habitat

export default {
  ImageSliderBigBlock: withLazyUnveil({
    loadComponent: () =>
      System.import(
        /* webpackChunkName: "ImageSliderBigBlock" */
        './component'
      ),
    height: 960,
    mobileHeight: 340,
  }),
}

/* global System */
const loadSlick = () =>
  System.import(
    /* webpackChunkName: "slick" */
    'slick-carousel/slick/slick'
  )

window.blockLines = (showLines, showSpacer) => {
  if (showLines) {
    document.body.classList.add('block-lines')
  } else {
    document.body.classList.remove('block-lines')
  }

  if (!showSpacer) {
    document.body.classList.add('no-spacer')
  } else {
    document.body.classList.remove('no-spacer')
  }

  return `${showLines ? 'show' : 'hide'} block lines and ${
    showSpacer ? 'show' : 'hide'
  } spacer`
}

const dhsvCommon = {
  init() {
    this.overlayScripts(),
      this.scrollDown(),
      this.startBreadcrumbs(),
      this.animationBreadcrumbs(),
      this.setCssVars(),
      this.primaryMenu(),
      this.hoverIcons(),
      this.formLabelAnimation(),
      this.preecoFormLabelAnimation(),
      this.sliderMobile(),
      this.shareCounter(),
      this.weglotDynamic(),
      this.codeLang(),
      this.formUpdate(),
      $(window).scroll(() => {
        this.navFixed()
      })

    $(window).on('resize', function () {
      if (typeof this.setCssVars === 'function') {
        this.setCssVars()
      }
    })

    $(window).on('load', function () {
      document.documentElement.style.setProperty(
        '--full-width',
        `calc(100vw - ${window.innerWidth - $(window).width()}px)`
      )

      // Tabs
      if ($(window).width() < 768) {
        var tabs = $(document).find('.tabs-nav ul')
        var tabs_price = $(document).find('.dhsv-prices-tabs--nav ul')
        if (tabs.length > 0) {
          loadSlick().then(() =>
            tabs.each(function () {
              $(this).slick({
                arrows: false,
                dots: false,
                infinite: false,
                variableWidth: true,
              })
            })
          )
        }
        if (tabs_price.length > 0) {
          loadSlick().then(() =>
            tabs_price.each(function () {
              $(this).slick({
                arrows: false,
                dots: false,
                infinite: false,
                variableWidth: true,
              })
            })
          )
        }
      }

      // Intercom Chat
      setTimeout(
        $(document)
          .find('body a')
          .each(function () {
            if ($(this).attr('href') == '#chat') {
              $(this).attr('href', 'javascript:Intercom("show")')
            }
          }),
        1500
      )

      $(document)
        .find('body a')
        .each(function () {
          if ($(this).attr('href') == '#chat') {
            $(this).attr('href', 'javascript:Intercom("show")')
          }
        })

      $('div').on('click', 'a[href="#chat"]', function (e) {
        e.stopPropagation()
        window.Intercom('show')
      })

      // Calendly
      function replaceCalendlyLink() {
        const lang = window.theme_global_data.currentLang

        $('a[href*="calendly.com"]').each(function () {
          let href = $(this).attr('href')
          // Remove whitespace
          href.replace(/\s+/g, '')
          let newHref

          // Check if there is another link for this language if the current lang isn't german
          // If not, check if there is an english link and use it as fallback
          if (lang !== 'de') {
            const calendlyLinks = window.theme_global_data.calendlyLinks

            let linkObj = calendlyLinks.flatMap(obj =>
              obj.links.find(link => link.lang === 'de' && link.url === href)
                ? obj
                : []
            )
            linkObj = linkObj.length > 0 ? linkObj[0] : undefined

            if (linkObj) {
              const langLink = linkObj.links.find(link => link.lang === lang)
              if (langLink) {
                newHref = langLink.url
              } else {
                const englishLink = linkObj.links.find(
                  link => link.lang === 'en'
                )
                if (englishLink) {
                  newHref = englishLink.url
                }
              }
            }
          }

          if (newHref) {
            href = newHref
          }

          $(this).attr('href', '#')
          $(this).attr(
            'onclick',
            `window.Calendly.initPopupWidget({url:'${href}'}); return false;`
          )
        })
      }

      setTimeout(() => {
        replaceCalendlyLink()
      }, 1500)

      replaceCalendlyLink()
    })

    $(document).on('gform_confirmation_loaded', function (event, formId) {
      if (
        $(document)
          .find('#gform_confirmation_wrapper_' + formId)
          .closest('.dhsv-newsletter-block').length
      ) {
        $(document)
          .find('#gform_confirmation_wrapper_' + formId)
          .closest('.dhsv-newsletter-block')
          .addClass('submited')
      }
    })

    $('body').on(
      'click',
      'a[href^="#"]:not([href="#"],[href="#0"],[href^="#fancy-"],[data-toggle],[role="tab"],[data-vc-accordion],[data-vc-tabs])',
      e => {
        this.smoothScroll($(e.currentTarget).attr('href'), e)
      }
    )

    // scrollspy for Single Post
    if ($('#post-contents').length) {
      $('body').scrollspy({ target: '#post-contents', offset: 80 })
    }

    if (window.location.hash) {
      this.smoothScroll(window.location.hash)
    }

    //Open New window on share
    $('.share-button').click(function (e) {
      e.preventDefault()
      var wpWidth = $(window).width()
      var wpHeight = $(window).height()
      window.open(
        $(this).attr('href'),
        'Share',
        'top=' +
          (wpHeight - 400) / 2 +
          ',left=' +
          (wpWidth - 600) / 2 +
          ',width=600,height=400'
      )
    })

    if ($('.post-sidebar').length) {
      if ($(window).width > 991) {
        let offset = $('.post-sidebar').offset()
        if ($(window).scrollTop() > offset.top - 200) {
          $('.post-sidebar')
            .addClass('is-fixed')
            .find('.post-meta')
            .slideUp(200)
        } else {
          $('.post-sidebar')
            .removeClass('is-fixed')
            .find('.post-meta')
            .slideDown(0)
        }

        $(window).scroll(function () {
          if ($(window).scrollTop() > offset.top - 200) {
            $('.post-sidebar')
              .addClass('is-fixed')
              .find('.post-meta')
              .slideUp(200)
          } else {
            $('.post-sidebar')
              .removeClass('is-fixed')
              .find('.post-meta')
              .slideDown(0)
          }
        })
      }
    }
  },

  overlayScripts() {
    const sections = document.querySelectorAll('.dhsv-overlay-section-block')
    const getBgWidth = (align, bgSpace, containerWidth) => {
      switch (align) {
        case 'left':
        case 'right':
          return `calc(100% - ${bgSpace}px)`
        case 'center':
          return `calc(100% - ${bgSpace * 2}px)`
        case 'left-small':
        case 'right-small':
          return `calc(100% - ${bgSpace * 2}px - ${containerWidth / 6}px)`
        case 'full-width':
          return `100%`
        default:
          return `100%`
      }
    }

    const handleResize = () => {
      Array.from(sections).forEach(section => {
        if (section) {
          const bgAlign = section.getAttribute('data-bg-align')
          const arrowAlign = section.getAttribute('data-arrow-align')
          const container = section.querySelector('.container')
          const editorEl = document.querySelector('.edit-post-visual-editor')
          const viewW = editorEl ? editorEl.clientWidth : window.innerWidth
          const rect = container.getBoundingClientRect()
          const bgSpace = (viewW - rect.width) / 4
          const containerWidth = rect.width
          section.querySelector('.background').style.width = getBgWidth(
            bgAlign,
            bgSpace,
            containerWidth
          )
          section.querySelector('.arrow-wrapper').style[arrowAlign] =
            bgSpace + 'px'
        }
      })
    }

    handleResize()
    window.addEventListener('resize', handleResize)
  },

  weglotDynamic() {
    const targetNode = document.body
    const observerOptions = {
      childList: true,
      attributes: true,
      subtree: true,
    }

    const { languages, currentLang } = window.theme_global_data

    const localOrigins = [
      'https://localhost:3000',
      'https://rb001.dev.360vier.net',
      'https://staging.raidboxes.io',
      'https://raidboxes.io',
      location.origin,
    ]

    const handleLinkChange = (el, inner = false) => {
      const element = inner ? el : el.target
      const href = element.getAttribute('href')
      const isInSwitcher = element.closest('ul.langs')
      const isTranslated =
        languages
          .map(x => x.language_to)
          .filter(lang => (href ? href.includes(`/${lang}/`) : false)).length >
        0
      const isLocal =
        localOrigins.filter(origin =>
          href
            ? href.includes(origin) || (href.startsWith('/') && !isTranslated)
            : false
        ).length > 0

      if (href && isLocal && !isTranslated && !isInSwitcher) {
        let dynamicHref = href
        localOrigins.forEach(origin => {
          dynamicHref = dynamicHref.replace(origin, '')
        })
        const newHref = `${
          currentLang !== 'de' ? '/' + currentLang : ''
        }${dynamicHref}`

        element.href = newHref
        //console.log('replaced link:', href, '->', newHref) //eslint-disable-line
      }
    }

    const observer = new MutationObserver(els => {
      els.forEach(el => {
        if (el.target.tagName === 'A') {
          handleLinkChange(el)
        }

        const childLinks = el.target.querySelectorAll('a')
          ? Array.from(el.target.querySelectorAll('a'))
          : []
        if (childLinks.length) {
          childLinks.forEach(child => {
            handleLinkChange(child, true)
          })
        }
      })
    })
    if (currentLang !== 'de') {
      console.log('WeglotDynamic') //eslint-disable-line
      observer.observe(targetNode, observerOptions)
    }
  },

  smoothScroll(link, e) {
    let destination = 0

    if (e) e.preventDefault()
    var hash = false
    if (!link && window.location.hash) {
      hash = window.location.hash
    } else if (link && link.indexOf('#') == 0) {
      hash = link
    } else if (
      link &&
      location.pathname.replace(/^\//, '') ==
        link.pathname.replace(/^\//, '') &&
      location.hostname == link.hostname
    ) {
      hash = link.hash
    }

    if (hash && $(hash).length) {
      destination = $(hash).offset().top - 75
      history.replaceState(null, null, hash)
    }

    $('html, body').animate(
      {
        scrollTop: destination,
      },
      600
    )

    $(window).trigger('hashchange')
  },
  onResize() {
    $(window).on('resize', () => {
      this.sliderMobile()
      this.sliderColumnsResize()
      this.mobileTabs()
    })
  },
  setCssVars() {
    document.documentElement.style.setProperty(
      '--body-width',
      document.body.clientWidth + 'px'
    )
  },
  navFixed() {
    var scroll = $(window).scrollTop()
    if (scroll > 75) {
      $('body').addClass('fixed')
      var btn = $('.banner').find('.btn-primary').clone()
      if ($(document).find('.header-menu .btn-primary').length === 0) {
        $('.header-menu').find('ul.nav').append(btn)
        $(document).find('.header-menu .btn-primary').addClass('btn-clone')
      }
    } else {
      $('body').removeClass('fixed')
      $('.header-menu').find('.btn-clone').remove()
    }
  },

  scrollDown() {
    $('.down-arrow').on('click', function (e) {
      e.preventDefault()

      if ($(this).closest('section').next('section').length > 0) {
        var destination = $(this).closest('section').next('section').offset()
          .top
        $('html, body').animate(
          {
            scrollTop: destination,
          },
          600
        )
      }
    })

    $(document).on('click', '.post-meta a[href^="#"]', function (event) {
      event.preventDefault()

      $('html, body').animate(
        {
          scrollTop: $($.attr(this, 'href')).offset().top,
        },
        1000
      )
    })
  },

  startBreadcrumbs() {
    setTimeout(function () {
      $('.hover-menu').removeClass('hideItem')
      $('.breadcrumb').removeClass('showItem')
    }, 3000)
  },

  animationBreadcrumbs() {
    setTimeout(function () {
      $('.hover-menu').mouseover(function () {
        $(this).addClass('hideItem')
        $('.breadcrumb').addClass('showItem')
      })
      $('.hover-menu').mouseout(function () {
        $(this).removeClass('hideItem')
        $('.breadcrumb').removeClass('showItem')
      })
      $('.breadcrumb').mouseover(function () {
        $('.hover-menu').addClass('hideItem')
        $(this).addClass('showItem')
      })
      $('.breadcrumb').mouseout(function () {
        $('.hover-menu').removeClass('hideItem')
        $(this).removeClass('showItem')
      })
    }, 3100)
  },
  primaryMenu() {
    $('.nav-primary ul.nav > li.menu-item > a').on('mouseover', function () {
      let heightSubmenu = 0,
        heightHeader = $('header.header-menu').outerHeight()
      if (
        $(this).parent().find('> ul.dropdown-menu > li.menu-item-type-yspl_win')
          .length
      ) {
        heightSubmenu = $(this)
          .parent()
          .find('> ul.dropdown-menu > li.menu-item-type-yspl_win')
          .outerHeight()
      } else {
        heightSubmenu = $(this).next('.dropdown-menu').outerHeight()
      }

      document.documentElement.style.setProperty(
        '--submenu-height',
        heightSubmenu + heightHeader - 15 + 'px'
      )
    })
  },

  hoverIcons() {
    $('.teaser-short').mouseover(function () {
      $(this).find('.teaser-short__scribble').toggle()
      $(this).find('.teaser-short__icon').toggle()
      $(this).find('.teaser-short__desc').toggle()
    })
    $('.teaser-short').mouseout(function () {
      $(this).find('.teaser-short__scribble').toggle()
      $(this).find('.teaser-short__icon').toggle()
      $(this).find('.teaser-short__desc').toggle()
    })
  },

  formLabelAnimation() {
    const selector =
      'input:not([type="checkbox"]):not([type="radio"]), textarea, select'
    $('body')
      .on('focus', selector, function (e) {
        if (e.type === 'focusin') {
          $(this).closest('.gfield').addClass('focusin')
        }

        $(this).closest('.gfield').addClass('active')
        $(this).closest('.gfield').find('label').addClass('active')
      })
      .on('focusout', selector, function () {
        $(this).closest('.gfield').removeClass('focusin')
        if ($(this).val() === '') {
          $(this).closest('.gfield').removeClass('active')
          $(this).closest('.gfield').find('label').removeClass('active')
        }
      })

    //set labe position for gfield select
    setTimeout(function () {
      $('body')
        .find('.gfield select')
        .each(function () {
          if ($(this).val() !== '') {
            $(this).closest('.gfield').addClass('active')
            $(this).closest('.gfield').find('label').addClass('active')
          }
        })
    }, 1500)
  },
  preecoFormLabelAnimation() {
    const selector =
      'input:not([type="checkbox"]):not([type="radio"]), textarea'
    $('body')
      .on('focus', selector, function (e) {
        if (e.type === 'focusin') {
          $(this).closest('label').addClass('focusin')
        }

        $(this).closest('label').addClass('active')
        $(this).closest('label > span').addClass('active')
      })
      .on('focusout', selector, function () {
        $(this).closest('label').removeClass('focusin')
        if ($(this).val() === '') {
          $(this).closest('label').removeClass('active')
          $(this).closest('label > span').removeClass('active')
        }
      })

    //set labe position for gfield select
    setTimeout(function () {
      $('body')
        .find('.preeco-form select')
        .each(function () {
          $(this).closest('label').addClass('active')
          $(this).closest('label > span').addClass('active')
        })
      $('body')
        .find('input[type="submit"]')
        .closest('div')
        .addClass('btn btn-primary')
    }, 1500)
  },
  formUpdate() {
    $(document).on('gform_post_render', function (event, form_id) {
      $(window).on('load', function () {
        $(document)
          .find('#gform_' + form_id)
          .find('.gfield')
          .each(function () {
            if (
              $(this).find('input').val() != '' ||
              $(this).find('select').val() != '' ||
              $(this).find('textarea').val() != ''
            ) {
              $(this).find('.gfield_label').addClass('active')
            }
          })
        dhsvCommon.formLabelAnimation()
      })
    })
  },
  sliderMobile() {
    window.addEventListener('load', () => {
      if (window.innerWidth < 768) {
        $('.main > .container > div').each(function () {
          if ($(this).find('div.teaser-box').length !== 0) {
            loadSlick().then(() =>
              $(this).find('.wp-block-columns').slick({
                infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true,
              })
            )
          }
        })
      }
    })
  },
  sliderColumnsResize() {
    if (window.innerWidth < 768) {
      $('.main > .container > div').each(function () {
        if ($(this).find('div.teaser-box').length !== 0) {
          loadSlick().then(() =>
            $(this).find('.wp-block-columns').not('.slick-initialized').slick({
              infinite: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
              dots: true,
            })
          )
        }
      })
    } else {
      $('.main > .container > div').each(function () {
        if ($(this).find('div.teaser-box').length !== 0) {
          loadSlick().then(() =>
            $(this).find('.wp-block-columns.slick-slider').slick('unslick')
          )
        }
      })
    }
  },
  shareCounter() {
    $('.share-links li a').on('click', function () {
      var post_id = $('.social__counter').attr('data-post-id')
      var data = {
        action: 'shareCount',
        post_id: post_id,
      }
      /* eslint-disable */
      $.post(ajaxurl, data, function (response) {
        $(document).find('.social__counter .number').text(response.new_count)
      })
    })
  },
  mobileTabs() {
    if ($(window).width() < 768) {
      var tabs = $(document).find('.tabs-nav ul')
      var tabs_price = $(document).find('.dhsv-prices-tabs--nav ul')
      if (tabs.length > 0) {
        loadSlick().then(() =>
          tabs.each(function () {
            $(this).slick({
              arrows: false,
              dots: false,
              infinite: false,
              variableWidth: true,
            })
          })
        )
      }
      if (tabs_price.length > 0) {
        loadSlick().then(() =>
          tabs_price.each(function () {
            $(this).slick({
              arrows: false,
              dots: false,
              infinite: false,
              variableWidth: true,
            })
          })
        )
      }
    }
  },
  codeLang() {
    $('.wp-block-code').each(function () {
      var lang = $(this).find('code').attr('lang')
      if (lang !== '') {
        $(this).append('<span class="lang-code">' + lang + '</span>')
      }
    })
  },
}

export default {
  init() {
    // JavaScript to be fired on all pages
    dhsvCommon.init()
    dhsvCommon.onResize()
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
  customLabelAnimation() {
    dhsvCommon.formLabelAnimation()
  },
  updateForm() {
    dhsvCommon.formUpdate()
  },
}

export const langs = {
  de: 'Deutsch',
  en: 'English',
  nl: 'Nederlands',
  es: 'Español',
  fr: 'Français',
  sv: 'Svenska',
  cs: 'Čeština',
  pl: 'Polski',
  it: 'Italiano',
  pt: 'Português',
}

export function getFullLangName(short) {
  return langs[short] || 'Deutsch'
}
